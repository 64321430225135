import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Select from 'react-select';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { toDeciTable, toPercTable, formatDate, confidenceLevelToInterval } from 'helpers/formatter';
import { removeEmptyCols } from 'helpers/data-frame';
import { parseSearchParams } from 'helpers/location';
import { getAdvancedAnalyticsFamaFrench } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const commonCellFormatter = (value, row, col) =>
  ['Alpha', 'R2'].includes(row)
    ? toPercTable(value)
    : ['Alpha Significance', 'Beta Significance', 'SMB Significance', 'HML Significance', 'MOM Significance'].includes(
        row,
      )
    ? value
    : toDeciTable(value);

const factorOptions = optionizeAll(['Alpha', 'R2', 'Correlation']);

function SingleAssetFamaFrench({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [selectedFactor, setSelectedFactor] = useState(factorOptions[0]);

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsFamaFrench(params);
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setResult(data);
      const _compactAsset = removeEmptyCols(data.asset);
      const _durations = _compactAsset.columns.filter((v) => v !== 'Since Inception');

      const _durationOptions = optionizeAll(_durations, { labelFormatter: _.upperFirst });
      setDurations(_durationOptions);
      if (_durationOptions.length > 0) setSelectedDuration(_durationOptions[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params) fetchResult();
  }, [params]);

  const joinTightDict = useMemo(() => {
    if (!result) return null;

    const ind = result.asset.columns.findIndex((v) => v === selectedDuration.value);

    const index = result.asset.index.concat();
    const columns = [result.info['Fund Name'], result.info['Benchmark Name']];
    const data = index.map((row, i) => [result.asset.data[i][ind], result.benchmark.data[i][ind]]);

    return { index, columns, data };
  }, [result, selectedDuration]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <AssetInfo
        asset={params.product}
        database={params.database}
        data={result}
        valueMap={{
          name: 'info.Fund Name',
          description: 'info.Fund Description',
          benchmark: 'info.Benchmark Name',
          geography: 'info.Geography Selection',
          startDate: 'info.Dates.0',
          endDate: 'info.Dates.1',
          cumulative: 'info.cumul_return',
          cagr: 'info.cagr',
          deviation: 'info.std',
        }}
        geographyTitle="Fama–French Model"
      />

      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Fama French Analysis
          <TimePeriod dates={_.get(result, 'info.Since Inception Date')} />
          <div className="tw-text-sm">Confidence Interval: {confidenceLevelToInterval(params.significance)}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="fama-french-analysis"
            data={result.asset}
            cols={(cols) => cols.filter((col) => col !== 'Commonolized')}
            indexName=""
            cellFormatter={commonCellFormatter}
            compact
            title={[
              'Fama French Analysis',
              formatDates(_.get(result, 'info.Since Inception Date')),
              `Confidence Interval: ${confidenceLevelToInterval(params.significance)}`,
            ]}
          />
        </CardBody>
      </Card>
      {durations && durations.length > 0 && (
        <Row>
          <Col xl="8" className="offset-xl-2">
            <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
            <Select
              className="react-select mw-200px"
              name="duration"
              value={selectedDuration}
              onChange={setSelectedDuration}
              options={durations}
            />
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div className="small">
                  {formatDate(_.get(result, `info.date_range.${selectedDuration.value}.0`))}&nbsp;-&nbsp;
                  {formatDate(_.get(result, `info.date_range.${selectedDuration.value}.1`))}
                </div>
              </CardHeader>
              <CardBody>
                <TightTable
                  suffix="fama-french"
                  data={joinTightDict}
                  indexName=""
                  cellFormatter={commonCellFormatter}
                  compact
                  title={[
                    formatDates(
                      _.get(result, `info.date_range.${selectedDuration.value}.0`),
                      _.get(result, `info.date_range.${selectedDuration.value}.1`),
                    ),
                  ]}
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <TightChart
                  data={joinTightDict}
                  base="rows"
                  rows={['Beta', 'SMB', 'HML', 'MOM']}
                  // rows={
                  //   includeBenchmarkOption.value
                  //     ? [result.info['Fund Name'], result.info.Benchmark]
                  //     : [result.info['Fund Name']]
                  // }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <Row>
        <Col xl="8" className="offset-xl-2">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-200px"
            name="factor"
            value={selectedFactor}
            onChange={setSelectedFactor}
            options={factorOptions}
          />
          <Card>
            <CardBody>
              <TightChart
                data={joinTightDict}
                base="rows"
                rows={[selectedFactor.value]}
                dataType={selectedFactor.value === 'Correlation' ? 'decimal' : 'percentage'}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default SingleAssetFamaFrench;
