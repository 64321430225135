import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Chart } from 'react-chartjs-2';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import LineChart from 'components/LineChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { colors } from 'helpers/chart';

function Drawdown({ data, params, notify, session }) {
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _assetKeys = data['summary table'].columns;
    const _assetOptions = optionizeAll(_assetKeys.filter((v, i) => i < _assetKeys.length - 1));
    setAssetOptions(_assetOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }
  }, [data]);

  const drawdownChart = useMemo(() => {
    if (!data) return null;
    const dframe = data['graph data'];

    const dataVal1 = [];

    _.each(dframe.data, (row) => {
      dataVal1.push(row[0] * 100);
    });

    const dataLabel1 = dframe.columns[0];

    const tdata = {
      labels: dframe.index.map((v) => v.substring(0, 7)),
      datasets: [
        {
          label: dataLabel1,
          data: dataVal1,
          borderColor: colors[0],
          backgroundColor: colors[5],
          pointRadius: 3,
          pointHoverRadius: 4,
          fill: true,
          borderWidth: 3,
          barPercentage: 1.6,
          tension: 0.4,
        },
      ],
    };

    const options = {
      plugins: {
        legend: { display: true },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.label}: ${toDeci(item.raw)}%`;
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 30,
            maxRotation: 0,
            minRotation: 0,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            callback: (val) => toDeci(val, { precision: 1, absolute: true, suffix: '%' }),
            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
          },
          beginAtZero: false,
        },
      },
    };

    return { data: tdata, options };
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Drawdown Analysis
          <div className="tw-text-lg">{params.product}</div>
          <TimePeriod dates={data['summary table dates']} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-drawdown"
            data={data['summary table']}
            indexName=""
            indexWidth="250px"
            flip={true}
            hideIndexTable={true}
            cellFormatter={(value, row, col) =>
              ['CAGR', 'Standard Deviation', 'Maximum Drawdown'].includes(col)
                ? toPercTable(value)
                : ['Calmar Ratio', 'MAR Ratio (RoMaD)', 'Sterling Ratio'].includes(col)
                ? toDeciTable(value)
                : value
            }
            title={['Drawdown Analysis', params.product, formatDates(data['summary table dates'])]}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Drawdown History
            </CardHeader>
            <CardBody>
              <LineChart title="Drawdown History" data={drawdownChart.data} options={drawdownChart.options} />
            </CardBody>
          </Card>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-span-6">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Drawdown Detail
              <div className="tw-text-lg">{params.product}</div>
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-performance-drawdown-detail"
                data={data['details table']}
                indexName=""
                indexWidth="250px"
                cellFormatter={(value, row, col) => (['Drawdown'].includes(col) ? toPercTable(value) : value)}
                title={['Drawdown Detail', params.product]}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Drawdown;
