import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import bg from 'assets/img/analyzers-bg.png';
import paba from 'assets/img/analyzers-pa-ba.png';
import pacp from 'assets/img/analyzers-pa-cp.png';

const Background = styled.div`
  text-align: center;
  color: #fff;
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  border-radius: 0.25rem;
  position: relative;
`;

function Analyzers({ session }) {
  const navigate = useNavigate();
  const { permissions } = session ?? {};

  return (
    <div className="tw-max-w-7xl tw-mx-auto tw-mt-10">
      <Background>
        <div className="tw-text-left">
          <button
            className="tw-mt-2 tw-ml-2 hover:tw-no-underline tw-font-semibold tw-px-[16px] tw-py-[9px] tw-border tw-border-solid tw-border-[#B20537] tw-text-[#B20537] tw-bg-white hover:tw-text-[#B20537] focus:tw-text-[#B20537]"
            onClick={() => navigate({ pathname: '/analyzers' }, { replace: false })}
          >
            Back
          </button>
        </div>
        <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-flex-wrap tw-px-3 tw-pb-12 md:tw-flex-row tw-cursor-pointer">
          {permissions.pa && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 lg:tw-w-1/2 hover:tw-underline tw-text-[#B20537]"
              onClick={() => navigate({ pathname: '/build-portfolio', search: 'single=true' }, { replace: false })}
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-text-[#B20537] tw-font-bold tw-leading-tight">
                Build &
                <br />
                Analyze
              </h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[210px] tw-mb-2"
                src={paba}
                alt="Discovery"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px] tw-text-[#fff]"></p>
            </div>
          )}

          {permissions.pa && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 lg:tw-w-1/2 hover:tw-underline tw-text-[#B20537]"
              onClick={() => navigate({ pathname: '/compare-portfolios' }, { replace: false })}
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-text-[#B20537] tw-font-bold tw-leading-tight">
                Compare
                <br />
                Portfolios
              </h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[210px] tw-mb-2"
                src={pacp}
                alt="Discovery"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px] tw-text-[#fff]"></p>
            </div>
          )}
        </div>
      </Background>
    </div>
  );
}

export default Analyzers;
