import React from 'react';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { AutoColumns } from 'components/Columns';
import TightTable from 'components/TightTable';
import SimpleTable from 'components/SimpleTable';
import FlexTable from 'components/FlexTable';

const hasAny = (v) => {
  if (_.isString(v) || _.isNil(v)) return !!v;
  return true;
};

function DynamicTables({ data, showHeaders }) {
  const keys = _.keys(data);

  return keys.map((key) => {
    const _data = data[key];
    const hasIndex = _data.index.some(hasAny);
    const hasColumn = _data.columns.some(hasAny);
    let simple = false;

    const processed = { index: _data.index, columns: _data.columns, data: _data.data };

    if (!hasIndex) {
      processed.index = _data.data.map(() => '');
      simple = _data.data[0].length === 1;
    }

    if (!hasColumn) {
      processed.columns = _data.data[0].map(() => '');
    }

    const crowded = _data.data[0].length > 3;
    const indexWidth = crowded ? '180px' : '230px';

    const card = (
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          {key}
        </CardHeader>
        <CardBody>
          {simple ? (
            <SimpleTable data={processed} showHeaders={showHeaders} suffix={_.kebabCase(key)} title={key} />
          ) : (
            <FlexTable
              suffix={_.kebabCase(key)}
              showHeaders={showHeaders}
              data={processed}
              indexWidth={indexWidth}
              title={key}
            />
          )}
        </CardBody>
      </Card>
    );

    if (crowded) return card;

    return <AutoColumns>{card}</AutoColumns>;
  });
}

export default DynamicTables;
