import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Chart } from 'react-chartjs-2';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import DownloadSimpleTable from 'components/DownloadSimpleTable';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import LineChart from 'components/LineChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { getAdvancedAnalyticsOverview } from 'services/analysis';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { parseSearchParams } from 'helpers/location';
import { colors } from 'helpers/chart';
import { riskMeasuresPerc } from 'helpers/meta';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const metricOptions = [
  {
    label: 'CAGR and Std Dev',
    value: { keys: ['CAGR', 'Standard Deviation'], type: 'percentage' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'CVaR and Max DD',
    value: { keys: ['CVaR 95%', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'Skew and Kurtosis',
    value: { keys: ['Skew', 'Kurtosis'], type: 'decimal' },
  },
  {
    label: 'Ratios (Sharpe, Sortino, Omega)',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'], type: 'decimal' },
  },
];

function SingleAssetOverview({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [selectedMetricOption, setSelectedMetricOption] = useState(metricOptions[0]);

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsOverview(params);
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setResult(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params) fetchResult();
  }, [params]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  const cumulativeReturnsChart = {
    data: {
      labels: _.keys(result.cumulative_return).map((v) => {
        const date = new Date(v);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;

        return `${year}-${_.padStart(month, 2, '0')}`;
      }),
      datasets: [
        {
          label: 'Cumulative Returns',
          borderColor: colors[0],
          backgroundColor: colors[5],
          pointRadius: 3,
          pointHoverRadius: 4,
          fill: true,
          borderWidth: 3,
          barPercentage: 1.6,
          tension: 0.4,
          data: _.values(result.cumulative_return),
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item, data) => `${item.dataset.label}: ${toDeci(item.raw)}`,
          },
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
            autoskip: true,
            autoSkipPadding: 20,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            color: 'black',
          },
          beginAtZero: false,
        },
      },
    },
  };

  return (
    <>
      <AssetInfo asset={params.product} database={params.database} data={result} />

      <PageTabs
        options={[
          { value: 'description', label: 'Description', permission: 'aa-overview-description' },
          { value: 'summary', label: 'Risk Metrics', permission: 'aa-overview-risk-metrics' },
        ]}
        session={session}
      >
        <TabPane tabId="description" role="tabpanel">
          <Row>
            <Col xl="8" className="offset-xl-2">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Value Add Monthly Index - VAMI
                  <div className="tw-text-lg">{params.product}</div>
                </CardHeader>
                <CardBody>
                  <LineChart
                    title="Value Add Monthly Index - VAMI"
                    data={cumulativeReturnsChart.data}
                    options={cumulativeReturnsChart.options}
                    height={400}
                    width={826}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <AutoColumns>
            <div className="tw-max-w-2xl tw-mx-auto">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Details
                </CardHeader>
                <CardBody>
                  <DownloadSimpleTable
                    data={result.detail_df}
                    suffix={`advanced-analytics-overview-details`}
                    title="Details"
                  ></DownloadSimpleTable>
                  <Table bordered striped>
                    <tbody>
                      {result.detail_df.index.map((row, i) => {
                        let value = result.detail_df.data[i][0];
                        if (['Management Fee', 'Performance Fee'].includes(row)) value = toPercTable(value);
                        return (
                          <tr>
                            <td>{row}</td>
                            <td>{value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </AutoColumns>
        </TabPane>

        <TabPane tabId="summary" role="tabpanel">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Metrics
              <div className="tw-text-lg">{params.product}</div>
              <TimePeriod dates={[_.get(result, 'info_df. .Start Date'), _.get(result, 'info_df. .End Date')]} />
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-summary"
                data={result.main_df}
                cellFormatter={(value, row, col) =>
                  riskMeasuresPerc.includes(row) ? toPercTable(value) : toDeciTable(value)
                }
                title={['Risk Metrics', params.product]}
              />
            </CardBody>
          </Card>

          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-start-2 tw-col-span-4">
              <div className="tw-flex">
                <div className="">
                  <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
                  <Select
                    className="react-select tw-w-[400px] tw-inline-block"
                    value={selectedMetricOption}
                    onChange={setSelectedMetricOption}
                    options={metricOptions}
                  />
                </div>
              </div>

              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {params.product}
                </CardHeader>
                <CardBody>
                  <TightChart
                    title={params.product}
                    data={result.main_df}
                    cols={selectedMetricOption.value.keys}
                    dataType={selectedMetricOption.value.type}
                    flip
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </TabPane>
      </PageTabs>
    </>
  );
}

export default SingleAssetOverview;
