import _ from 'lodash';

export const toDeci = (
  val,
  { decorate = false, absolute = false, precision = 2, formatter = (v) => v, prefix = '', suffix = '' } = {},
) => {
  let value = parseFloat(val);
  if (isNaN(value)) return val;

  value = formatter(value);
  const isNegative = value < 0;

  const format = (num) => num.toFixed(precision).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + suffix;
  value = isNegative && absolute ? `(${prefix}${format(Math.abs(value))})` : `${prefix}${format(value)}`;

  if (decorate && isNegative) return { style: { color: 'red' }, value };
  return value;
};

export const toDeciTable = (val, options = {}) => {
  return toDeci(val, { ...options, decorate: true, absolute: true });
};

export const toPerc = (val, options = {}) => {
  return toDeci(val, { ...options, formatter: (v) => v * 100, suffix: '%' });
};

export const toPercTable = (val, options = {}) => {
  return toPerc(val, { ...options, decorate: true, absolute: true });
};

export const confidenceLevelToInterval = (level) => (1 - level) * 100 + '%';

export const isEmptyTightData = (tight) => tight.data.every((row) => row.every((col) => !col));

export const removeEmptyRows = (tight) => {
  const data = [];
  const index = [];
  _.each(tight.data, (row, ind) => {
    if (row.some((item) => item)) {
      data.push(row);
      index.push(tight.index[ind]);
    }
  });

  tight.data = data;
  tight.index = index;

  return tight;
};

export const formatDate = (d) => {
  const dt = new Date(d);
  return dt.toLocaleString('en-CA', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export const formatDate2 = (d) => {
  const date = new Date(d);
  if (!_.isDate(date) || isNaN(date)) return d;

  const options = { month: 'short', year: 'numeric' };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(date);
  const parts = formattedDate.split(' ');
  const formattedDateWithHyphen = parts[0] + '-' + parts[1];
  return formattedDateWithHyphen;
};

export const formatDateShort = (d) => {
  const date = new Date(d);
  if (!_.isDate(date) || isNaN(date)) return d;

  return date.toLocaleDateString('en-CA', { month: '2-digit', year: 'numeric' });
};

export const formatCurrency = (num, { precision = 2 } = {}) => {
  if (!_.isNumber(num)) return '';

  return (
    '$' +
    Number(num)
      .toFixed(precision)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};

export const tablePresets = {
  percentage: (val) => toPercTable(val),
  'MAR Ratio (RoMaD)': (val) => toDeciTable(val),
  Correlation: (val) => toDeciTable(val),
  corr: (val) => toDeciTable(val),
  'Treynor Ratio': (val) => toDeciTable(val),
  Beta: (val) => toDeciTable(val),
  beta: (val) => toDeciTable(val),
  'Sharpe Ratio': (val) => toDeciTable(val),
  sharpe: (val) => toDeciTable(val),
  sortino: (val) => toDeciTable(val),
  CAGR: (val) => toPercTable(val),
  cagr: (val) => toPercTable(val),
  'Cumulative Return': (val) => toPercTable(val),
  'CVaR 95%': (val) => toPercTable(val),
  cvar: (val) => toPercTable(val),
  'Standard Deviation': (val) => toPercTable(val),
  'std dev': (val) => toPercTable(val),
  'Maximum Drawdown': (val) => toPercTable(val),
  'max dd': (val) => toPercTable(val),
  R2: (val) => toPercTable(val, { precision: 0 }),
  VAMI: (val) => (val ? formatCurrency(val, { precision: 0 }) : ''),
};

export const dataTypePresets = {
  Beta: 'decimal',
  Correlation: 'decimal',
  'Treynor Ratio': 'decimal',
  Alpha: 'percentage',
  'Sharpe Ratio': 'decimal',
  CAGR: 'percentage',
  'Standard Deviation': 'percentage',
  'Maximum Drawdown': 'percentage',
  "Jensen's Alpha": 'percentage',
  'Unsystematic Risk': 'percentage',
  'Systematic Risk': 'percentage',
  R2: 'whole-percentage',
  VAMI: 'whole-currency',
};

export const getDataType = (label) => {
  return dataTypePresets[label] || 'text';
};
