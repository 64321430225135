import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane } from 'reactstrap';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { colors } from 'helpers/chart';

function Returns({ data, params, options = {}, notify, session }) {
  const [yearOptions, setYearOptions, selectedYearOption, setSelectedYearOption] = useArrayState();
  const [holdingOptions, setHoldingOptions, selectedHoldingOption, setSelectedHoldingOption] = useArrayState();
  const [
    monthlyHoldingOptions,
    setMonthlyHoldingOptions,
    selectedMonthlyHoldingOption,
    setSelectedMonthlyHoldingOption,
  ] = useArrayState();
  const [hasPortfolioData, setHasPortfolioData] = useState(false);

  useEffect(() => {
    if (!data) return;

    const _holdings = data.calendar_year_return.columns.filter((col) => ![params.port1Name].includes(col));
    const _holdingOptions = optionizeAll(_holdings);
    setHoldingOptions(_holdingOptions);
    if (_holdingOptions.length > 0) setSelectedHoldingOption(_holdingOptions[0]);

    const _years = _.orderBy(data.calendar_year_return.index, null, 'desc');
    const _yearOptions = optionizeAll(_years);
    setYearOptions(_yearOptions);
    if (_yearOptions.length > 0) setSelectedYearOption(_yearOptions[0]);
  }, [data]);

  useEffect(() => {
    if (!data || !selectedYearOption) return;

    const targetCols = data.monthly_returns[selectedYearOption.value].columns;

    const _hasPortfolioData = targetCols.some((col) => col === params.port1Name);
    setHasPortfolioData(_hasPortfolioData);

    const _monthlyHoldings = targetCols.filter((col) => ![params.port1Name].includes(col));
    const _monthlyHoldingOptions = optionizeAll(_monthlyHoldings);
    setMonthlyHoldingOptions(_monthlyHoldingOptions);
    if (_monthlyHoldingOptions.length > 0) setSelectedMonthlyHoldingOption(_monthlyHoldingOptions[0]);
  }, [data, selectedYearOption]);

  const calendarYearReturnsChartData = useMemo(() => {
    if (!data || !selectedHoldingOption) return;

    const df = data.calendar_year_return;

    const holdiInd = df.columns.findIndex((col) => col === selectedHoldingOption.value);
    const port1Ind = df.columns.findIndex((col) => col === params.port1Name);

    const _columns = [selectedHoldingOption.value, params.port1Name];
    const _index = [];
    const _data = [];

    _.forEach(df.data, (row, i) => {
      if (row[port1Ind]) {
        _index.push(df.index[i]);
        _data.push([row[holdiInd], row[port1Ind]]);
      }
    });

    return {
      columns: _columns,
      index: _index,
      data: _data,
    };
  }, [data, selectedHoldingOption]);

  if (!calendarYearReturnsChartData) return null;

  return (
    <>
      <PageTabs
        options={[
          { value: 'calendar-year', label: 'Calendar Year' },
          { value: 'monthly', label: 'Monthly' },
        ]}
        colorIndex={0}
      >
        <TabPane tabId="calendar-year" role="tabpanel">
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Calendar Year Returns
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-performance-returns"
                data={data.calendar_year_return}
                indexName=""
                cellWidth="85px"
                cellFormatter={(value, row, col) => toPerc(value, { decorate: true, absolute: true })}
                title={['Calendar Year Returns']}
                {...options.calendarYearTable}
              />
            </CardBody>
          </Card>

          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-start-2 tw-col-span-4">
              {holdingOptions.length > 1 && (
                <>
                  <div className="tw-text-left tw-font-bold">SELECT</div>
                  <Select
                    className="react-select mw-400px"
                    value={selectedHoldingOption}
                    onChange={setSelectedHoldingOption}
                    options={holdingOptions}
                  />
                </>
              )}
              {selectedHoldingOption && (
                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    Calendar Year Returns
                  </CardHeader>
                  <CardBody>
                    <TightChart
                      title="Calendar Year Returns"
                      data={calendarYearReturnsChartData}
                      cols={[selectedHoldingOption.value, params.port1Name]}
                      wraplabelX={true}
                      dataType="percentage"
                      colors={[colors[0], colors[4]]}
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tabId="monthly" role="tabpanel">
          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            <div className="tw-col-span-6">
              <div className="tw-text-left tw-font-bold">SELECT YEAR</div>
              <Select
                className="react-select mw-400px"
                value={selectedYearOption}
                onChange={setSelectedYearOption}
                options={yearOptions}
              />
              {selectedYearOption && (
                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    Monthly Returns
                    <div className="tw-text-lg">{selectedYearOption.label}</div>
                  </CardHeader>
                  <CardBody>
                    <TightTable
                      suffix="risk-performance-returns-year-month"
                      data={data.monthly_returns[selectedYearOption.value]}
                      flip={true}
                      indexName=""
                      useSort={true}
                      rowDecorator={(rowTitle, rowInd, rowTitles) =>
                        rowTitle === params.benchmark
                          ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } }
                          : undefined
                      }
                      cellFormatter={(value, row, col) => toPerc(value, { decorate: true, absolute: true })}
                      title={['Monthly Returns', selectedYearOption.label]}
                      {...options.monthlyReturnTable}
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </div>

          {hasPortfolioData && monthlyHoldingOptions.length > 0 && (
            <AutoColumns>
              <>
                {monthlyHoldingOptions.length > 1 && (
                  <>
                    <div className="tw-text-left tw-font-bold">SELECT</div>
                    <Select
                      className="react-select mw-400px"
                      value={selectedMonthlyHoldingOption}
                      onChange={setSelectedMonthlyHoldingOption}
                      options={monthlyHoldingOptions}
                    />
                  </>
                )}

                {selectedYearOption && selectedMonthlyHoldingOption && (
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Monthly Returns
                      <div className="tw-text-lg">{selectedYearOption.label}</div>
                    </CardHeader>
                    <CardBody>
                      <TightChart
                        title="Monthly Returns"
                        cols={[selectedMonthlyHoldingOption.value, params.port1Name]}
                        data={data.monthly_returns[selectedYearOption.value]}
                        dataType="percentage"
                        colors={[colors[0], colors[4]]}
                      />
                    </CardBody>
                  </Card>
                )}
              </>
            </AutoColumns>
          )}
        </TabPane>
      </PageTabs>
    </>
  );
}

export default Returns;
