import axios from 'axios';
import store from 'store2';

export const instance = axios.create({
  baseURL: `/api`,
  timeout: 0,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

instance.interceptors.request.use(
  async (config) => {
    if (config.headers['x-app-cache'] !== 'true') return config;

    const key = generateCacheKey(config);

    if (store.has(key)) {
      config.adapter = function (_config) {
        return new Promise((resolve, reject) => {
          const res = {
            data: store.get(key),
            status: 200,
            statusText: 'OK',
            headers: { 'content-type': 'application/json' },
            config: _config,
            request: {},
          };

          return resolve(res);
        });
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    if (response.config.headers['x-app-cache'] !== 'true') return response;

    const key = generateCacheKey(response.config);
    store.set(key, response.data);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

function generateCacheKey(config) {
  let key = config.url + config.method;
  if (config.data) {
    key += JSON.stringify(regenerateObject(config.data));
  }

  return encodeURI(key);
}

function regenerateObject(obj) {
  if (typeof obj === 'string') {
    obj = JSON.parse(obj);
  }

  if (Array.isArray(obj)) {
    // obj.sort();

    obj.forEach((item, index) => {
      if (typeof item === 'object' && item !== null) {
        obj[index] = regenerateObject(item);
      }
    });
  } else if (typeof obj === 'object' && obj !== null) {
    const sortedKeys = Object.keys(obj).sort();

    const sortedObj = {};

    sortedKeys.forEach((key) => {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        sortedObj[key] = regenerateObject(value);
      } else {
        sortedObj[key] = value;
      }
    });

    return sortedObj;
  }

  return obj;
}
