import React from 'react';
import _ from 'lodash';
import { Table } from 'reactstrap';
import DownloadSimpleTable from './DownloadSimpleTable';

const hasAny = (v) => {
  if (_.isString(v) || _.isNil(v)) return !!v;
  return true;
};

function SimpleTable({ data, showHeaders, suffix, title }) {
  if (!data) return null;

  const showIndex = data.index.some(hasAny);

  if (showHeaders) {
    showHeaders = data.columns.some(hasAny);
  }

  return (
    <>
      <DownloadSimpleTable data={data} suffix={suffix} title={title}></DownloadSimpleTable>
      <Table bordered striped>
        <>
          {showHeaders && (
            <thead>
              <tr>
                <th></th>
                {data.columns.map((val) => {
                  return (
                    <th>
                      <span className="tw-text-xs">{val}</span>
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {data.data.map((row, i) => {
              return (
                <tr>
                  {showIndex && <td>{data.index[i]}</td>}
                  {row.map((val) => {
                    return <td>{val}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </>
      </Table>
    </>
  );
}

export default SimpleTable;
