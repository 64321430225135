import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function CreditRisk({ data: { data, table_dates }, params, options = {}, notify, session }) {
  const [holdingOptions, setHoldingOptions] = useState([]);
  const [selectedHoldingOption, setSelectedHoldingOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _holdings = data.columns.filter((val) => ![params.port1Name, params.benchmark].includes(val));
    const _holdingOptions = optionizeAll(_holdings);
    setHoldingOptions(_holdingOptions);

    if (_holdingOptions.length > 0) {
      setSelectedHoldingOption(_holdingOptions[0]);
    }
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Exposure: Credit Spreads
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-credit-spreads"
            data={data}
            indexName=""
            indexWidth="250px"
            useSort={true}
            cellFormatter={(value, row, col, rowInd, colInd, flip) => {
              const label = flip ? col : row;
              return _.isBoolean(value)
                ? value
                : ['R2'].includes(label)
                ? toPercTable(value, { precision: 0 })
                : toDeciTable(value);
            }}
            title={['Exposure: Credit Spreads', formatDates(table_dates)]}
            {...options.creditSpreadTable}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          {holdingOptions.length > 1 && (
            <>
              <div className="tw-text-left tw-font-bold">SELECT HOLDING</div>
              <Select
                className="react-select tw-w-[400px]"
                value={selectedHoldingOption}
                onChange={setSelectedHoldingOption}
                options={holdingOptions}
              />
            </>
          )}
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Exposure: Credit Spreads
            </CardHeader>
            <CardBody>
              {selectedHoldingOption ? (
                <TightChart
                  title="Exposure: Credit Spreads"
                  data={data}
                  cols={['Beta', 'R2']}
                  rows={[params.port1Name, params.benchmark, selectedHoldingOption.value]}
                  flip={true}
                  wraplabelX={true}
                  dataType="decimal"
                />
              ) : (
                <TightChart
                  title="Exposure: Credit Spreads"
                  data={data}
                  cols={['Beta', 'R2']}
                  rows={[params.port1Name, params.benchmark]}
                  flip={true}
                  wraplabelX={true}
                  dataType="decimal"
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default CreditRisk;
