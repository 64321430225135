import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import { Chart } from 'react-chartjs-2';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import LineChart from 'components/LineChart';
import { AutoColumns } from 'components/Columns';
import DownloadSimpleTable from 'components/DownloadSimpleTable';
import { useArrayState } from 'components/CustomHooks';
import { riskMeasuresPerc } from 'helpers/meta';
import { toDeci, toPerc, toPercTable, toDeciTable, tablePresets, getDataType, formatCurrency } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { removeEmptyCols } from 'helpers/data-frame';
import { colors } from 'helpers/chart';

function Summary({ data, tables, params, notify, session }) {
  const [performanceDF, setPerformanceDF] = useState(null);
  const [objectOptions, setObjectOptions] = useState([]);
  const [selectedObjectOption, setSelectedObjectOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _performanceDF = removeEmptyCols(data.performance, ['15Y', '20Y']);
    const _objectOptions = optionizeAll(_performanceDF.index);
    setObjectOptions(_objectOptions);
    if (_objectOptions.length > 0) {
      setSelectedObjectOption(_objectOptions[0]);
    }
    setPerformanceDF(_performanceDF);
  }, [data]);

  const lineChart = useMemo(() => {
    if (!data) return null;
    const gdata = data['chart_data'];

    const dataVal1 = [];

    _.each(gdata.data, (row) => {
      dataVal1.push(row[0]);
    });

    const dataLabel1 = gdata.columns[0];

    const tdata = {
      labels: gdata.index.map((v) => v.substring(0, 7)),
      datasets: [
        {
          label: dataLabel1,
          data: dataVal1,
          borderColor: colors[0],
          backgroundColor: colors[5],
          pointRadius: 3,
          pointHoverRadius: 4,
          fill: true,
          borderWidth: 3,
          barPercentage: 1.6,
          tension: 0.4,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.label}: ${formatCurrency(item.raw, { precision: 0 })}`;
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            maxRotation: 0,
            autoskip: true,
            autoSkipPadding: 20,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            callback: (val) => formatCurrency(val, { precision: 0 }),
            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
          },
          beginAtZero: false,
        },
      },
    };

    return { data: tdata, options };
  }, [data]);

  const tableKeys = _.keys(tables);
  const tableCount = tableKeys.length;

  if (!performanceDF) return null;

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Performance
          <div className="tw-text-lg">{params.product}</div>
          <TimePeriod dates={data.table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="summary_performance"
            data={performanceDF}
            indexName=""
            cellFormatter={(value, row, col) => {
              const formatter = tablePresets[row];
              if (formatter) return formatter(value);

              return toDeciTable(value);
            }}
            title={['Performance', params.product, formatDates(data.table_dates)]}
          />
        </CardBody>
      </Card>

      {lineChart && (
        <AutoColumns>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              <div>Value Add Monthly Index - VAMI</div>
              <div className="tw-text-lg">(Growth of $1,000)</div>
              <div className="tw-text-lg">{params.product}</div>
            </CardHeader>
            <CardBody>
              <LineChart title="Value Add Monthly Index - VAMI" data={lineChart.data} options={lineChart.options} />
            </CardBody>
          </Card>
        </AutoColumns>
      )}

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
          <Select
            className="react-select mw-400px"
            value={selectedObjectOption}
            onChange={setSelectedObjectOption}
            options={objectOptions}
          />
          {selectedObjectOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                <div>{selectedObjectOption.label}</div>
                <div className="tw-text-lg">{params.product}</div>
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedObjectOption.label}
                  cols={[selectedObjectOption.value]}
                  rows={(rows) =>
                    rows.filter((row, ind) => {
                      if (['15Y', '20Y'].includes(row)) {
                        const col = performanceDF.index.findIndex((col) => col === selectedObjectOption.value);
                        return !!performanceDF.data[col][ind];
                      }

                      return true;
                    })
                  }
                  data={performanceDF}
                  flip={true}
                  dataType={getDataType(selectedObjectOption.value)}
                />
              </CardBody>
            </Card>
          )}
        </>
      </AutoColumns>

      {tableCount > 0 && (
        <div className="tw-grid tw-grid-cols-1 tw-content-start tw-items-stretch tw-gap-4 md:tw-grid-cols-12">
          {tableKeys.map((key, ind) => {
            return (
              <div className={classnames('md:tw-col-span-6', tableCount === 1 ? 'md:tw-col-start-4' : '')}>
                <Card>
                  <CardHeader tag="h4" className="mt-0 text-center">
                    {params.product}
                  </CardHeader>
                  <CardBody>
                    <DownloadSimpleTable
                      data={tables[key]}
                      suffix={`investment-analysis-summary-${key}`}
                      title={params.product}
                    ></DownloadSimpleTable>
                    <Table bordered striped>
                      <tbody>
                        {tables[key].index.map((row, i) => {
                          return (
                            <tr>
                              <td>{row}</td>
                              <td>{tables[key].data[i][0]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Summary;
