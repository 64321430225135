import { instance } from './axios';
import _ from 'lodash';

const getErrorMessage = (error) => _.get(error, 'response.data.message');
const headers = { 'x-app-cache': 'true' };

export async function listProducts(flat = true) {
  try {
    const data = await instance.get(`/analysis/products?flat=${flat}`, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getCharacteristicsDiscovery(flat = true) {
  try {
    const data = await instance
      .get(`/analysis/characteristics-discovery?flat=${flat}`, { headers })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getRiskPerformanceDiscovery() {
  try {
    const data = await instance.get(`/analysis/risk-performance-discovery`, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getDiscovery() {
  try {
    const data = await instance.get('/analysis/discovery', { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Fund Analysis
export async function getInvestmentAnalysisHeader(options) {
  try {
    const data = await instance
      .post('/analysis/ia-header', _.pick(options, ['product', 'database', 'significance', 'sdate', 'edate']), {
        headers,
      })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getInvestmentAnalysis(options) {
  try {
    const data = await instance
      .post('/analysis/ia', _.pick(options, ['product', 'database', 'significance', 'sdate', 'edate']), { headers })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getInvestmentAnalysisImpact(options) {
  try {
    const data = await instance.post('/analysis/ia-impact', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Fund Comparison
export async function getInvestmentComparisonHeader(options) {
  try {
    const data = await instance.post('/analysis/ic-header', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getInvestmentComparison(options) {
  try {
    const data = await instance.post('/analysis/ic', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getInvestmentComparisonImpact(options) {
  try {
    const data = await instance.post('/analysis/ic-impact', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Portfolio Analysis - Build - Analyze
export async function getPortfolioAnalysisBuildAnalyze(options) {
  if (!options.converter) options.converter = 'Default';

  try {
    const data = await instance.post('/analysis/pa-build-analyze', options, { headers }).then((res) => res.data);
    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Portfolio Analysis - Build - Advanced
export async function getPortfolioAnalysisBuildAdvanced(options) {
  if (!options.converter) options.converter = 'Default';

  try {
    const data = await instance.post('/analysis/pa-build-advanced', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Portfolio Analysis - Compare - Analyze
export async function getPortfolioAnalysisCompareAnalyze(options) {
  if (!options.converter) options.converter = 'Default';

  try {
    const data = await instance.post('/analysis/pa-compare-analyze', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Portfolio Analysis - Compare - Advanced
export async function getPortfolioAnalysisCompareAdvanced(options) {
  if (!options.converter) options.converter = 'Default';

  try {
    const data = await instance.post('/analysis/pa-compare-advanced', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Advanced Analytics
export async function getAdvancedAnalyticsOverview(assetOptions) {
  try {
    const data = await instance.post('/analysis/aa-overview', assetOptions, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsVar(assetOptions) {
  try {
    const data = await instance.post('/analysis/aa-var', assetOptions, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsReturns(assetOptions) {
  try {
    const data = await instance.post('/analysis/aa-returns', assetOptions, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsRiskContribution(assetOptions) {
  try {
    const data = await instance
      .post('/analysis/aa-risk-contribution', assetOptions, { headers })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsFamaFrench(assetOptions) {
  try {
    const data = await instance.post('/analysis/aa-fama-french', assetOptions, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsBenchmark(assetOptions) {
  try {
    const data = await instance.post('/analysis/aa-benchmark', assetOptions, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsRollingReturns(assetOptions) {
  try {
    const data = await instance.post('/analysis/aa-rolling-returns', assetOptions, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsPortfolioImpact(assetOptions) {
  try {
    const data = await instance
      .post('/analysis/aa-portfolio-impact', assetOptions, { headers })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsPortfolioImpactOptions() {
  try {
    const data = await instance.get('/analysis/aa-portfolio-impact-options', { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsFactorExposures(assetOptions) {
  try {
    const data = await instance
      .post('/analysis/aa-factor-exposures', assetOptions, { headers })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsFactorExposuresOptions() {
  try {
    const data = await instance.get('/analysis/aa-factor-exposures-options', { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getAdvancedAnalyticsDrawdown(assetOptions) {
  try {
    const data = await instance.post('/analysis/aa-drawdown', assetOptions, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Client Portfolio
export async function getClientPortfolioOptions() {
  try {
    const data = await instance.get('/analysis/client-portfolio-options', { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getClientPortfolio(options) {
  try {
    const data = await instance.post('/analysis/client-portfolio', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// My Flare Alternative Funds
export async function getMyflareAlternativeFundsOptions() {
  try {
    const data = await instance.get('/analysis/myflare-alternative-funds-options', { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getMyflareAlternativeFunds(options) {
  try {
    const data = await instance
      .post('/analysis/myflare-alternative-funds', options, { headers })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// My Flare Asset Classes
export async function getMyflareAssetClassesOptions() {
  try {
    const data = await instance.get('/analysis/myflare-asset-classes-options', { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getMyflareAssetClasses(options) {
  try {
    const data = await instance.post('/analysis/myflare-asset-classes', options, { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// My Flare Model Portfolios
export async function getModelPortfoliosOptions() {
  try {
    const data = await instance.get('/analysis/myflare-model-portfolios-options', { headers }).then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

export async function getModelPortfolios(options) {
  try {
    const data = await instance
      .post('/analysis/myflare-model-portfolios', options, { headers })
      .then((res) => res.data);

    return [data, null];
  } catch (err) {
    return [null, getErrorMessage(err)];
  }
}

// Backend Cache
export async function getCacheUpdateTime() {
  try {
    const data = await instance.get('/analysis/cache-update-time').then((res) => res.data);

    return data;
  } catch (err) {
    return null;
  }
}
