import { Fragment, useEffect, useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import classnames from 'classnames';

const menus = [
  {
    value: 'Fund Analysis',
    label: 'Fund Analysis',
    navigate: { pathname: '/analyzers/asset-discovery-ia', search: 'minass=true' },
    permission: 'ia',
  },
  {
    value: 'Fund Comparison',
    label: 'Fund Comparison',
    navigate: { pathname: '/analyzers/asset-discovery-ic', search: 'multi=true&minass=true' },
    permission: 'ic',
  },
  {
    value: 'Portfolio Analysis',
    label: 'Portfolio Analysis',
    permission: 'pa',
    subs: [
      {
        value: 'Build and Analyze',
        label: 'Build and Analyze',
        navigate: { pathname: '/build-portfolio', search: 'single=true' },
      },
      { value: 'Compare', label: 'Compare', navigate: { pathname: '/compare-portfolios' } },
    ],
  },
  // {
  //   value: 'Advanced Analytics',
  //   label: 'Advanced Analytics',
  //   navigate: { pathname: '/analyzers/asset-discovery-aa' },
  //   permission: 'aa',
  // },
];

function AnalyzerMenus({ session }) {
  const navigate = useNavigate();

  const permissions = session?.permissions || {};
  if (!permissions['member']) return null;

  const allowedMenus = menus.filter((menu) => permissions[menu.permission]);

  return (
    <>
      {allowedMenus.map((menu) => {
        return menu.navigate ? (
          <button
            className="tw-text-[#B20537] tw-border-0 tw-bg-transparent tw-font-bold tw-text-sm tw-ml-2 tw-border-none"
            type="button"
            onClick={() => {
              navigate(menu.navigate, { replace: false });
            }}
          >
            {menu.label}
          </button>
        ) : (
          <Menu as="span" className="tw-relative">
            <Menu.Button
              className="tw-text-[#B20537] tw-border-0 tw-bg-transparent tw-font-bold tw-text-sm tw-ml-2 tw-border-none"
              type="button"
            >
              {menu.label}
            </Menu.Button>
            <Menu.Items className="tw-absolute tw-border-gray-300 tw-border-solid tw-border tw-right-0 tw-z-10 tw-mt-2 tw-w-48 tw-origin-top-right tw-rounded-md tw-bg-white tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
              {menu.subs.map((sub) => {
                return (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className="tw-border-0 tw-bg-transparent tw-text-sm ml-2 tw-block tw-p-2 hover:tw-underline"
                        onClick={() => {
                          navigate(sub.navigate, { replace: false });
                        }}
                      >
                        {sub.label}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Menu>
        );
      })}
    </>
  );
}

export default AnalyzerMenus;
