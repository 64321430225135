import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import DownloadSimpleTable from './DownloadSimpleTable';

const hasAny = (v) => {
  if (_.isString(v) || _.isNil(v)) return !!v;
  return true;
};

function FlexTable({ data, showHeaders, indexName, indexWidth, suffix, title }) {
  if (!data) return null;

  const showIndex = data.index.some(hasAny);

  if (showHeaders) {
    showHeaders = data.columns.some(hasAny);
  }

  if (!indexWidth) indexWidth = '200px';

  const bgAltNumber = showHeaders ? 0 : 1;

  return (
    <>
      <DownloadSimpleTable data={data} suffix={suffix} title={title}></DownloadSimpleTable>
      <div className="tw-font-semibold">
        {showHeaders && (
          <div className="tw-flex">
            {showIndex && (
              <div className={`tw-flex-1 tw-border tw-border-gray-400 tw-border-solid tw-px-1 tw-py-2 break-anywhere`}>
                {indexName ?? ''}
              </div>
            )}
            {data.columns.map((val) => {
              return (
                <div className="tw-flex-1 tw-border tw-border-gray-400 tw-border-solid tw-px-1 tw-py-2 break-anywhere">
                  {val}
                </div>
              );
            })}
          </div>
        )}
        {data.data.map((row, i) => {
          return (
            <div
              className={classnames('tw-flex', {
                'tw-bg-[#F2F2F2]': i % 2 === bgAltNumber,
              })}
            >
              {showIndex && (
                <div
                  className={`tw-flex-1 tw-border tw-border-gray-400 tw-border-solid tw-px-1 tw-py-2 break-anywhere`}
                >
                  {data.index[i]}
                </div>
              )}
              {row.map((val) => {
                return (
                  <div className="tw-flex-1 tw-border tw-border-gray-400 tw-border-solid tw-px-1 tw-py-2 break-anywhere">
                    {val}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default FlexTable;
