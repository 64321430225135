import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeciTable, toPercTable, formatDateShort, formatCurrency } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function LeverageLeverageIlliquidity({ data, currency, params, notify, session }) {
  const [subjectOptions, setSubjectOptions, selectedSubjectOption, setSelectedSubjectOption] = useArrayState();
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    if (!data) return;

    const _subjects = data.columns;
    const _subjectsOptions = optionizeAll(_subjects);
    setSubjectOptions(_subjectsOptions);

    const _period = _.orderBy(data.index, [], ['desc']);
    const _periodOptions = optionizeAll(_period, { labelFormatter: formatDateShort });
    setPeriodOptions(_periodOptions);
  }, [data]);

  if (!selectedSubjectOption || !selectedPeriodOption) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Leverage & Illiquid Assets
          <div className="tw-text-lg">{params.product}</div>
          <div className="tw-text-lg">Currency: {currency}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="aum"
            data={data}
            indexFormatter={formatDateShort}
            dataType="currency"
            indexName="as of date"
            sortIndexes={[0]}
            cellFormatter={(value, row, col) =>
              ['Leverage'].includes(col) ? toDeciTable(value, { precision: 2 }) : toPercTable(value, { precision: 0 })
            }
            useSort
            title={['Leverage & Illiquid Assets', params.product, `Currency: ${currency}`]}
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select tw-w-[300px]"
            value={selectedSubjectOption}
            onChange={setSelectedSubjectOption}
            options={subjectOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {params.product}
              <div className="tw-text-lg">{selectedSubjectOption.label}</div>
            </CardHeader>
            <CardBody>
              <TightChart
                title={params.product}
                data={data}
                cols={[selectedSubjectOption.value]}
                dataType={selectedSubjectOption.value === 'Leverage' ? 'decimal' : 'percentage'}
                options={{
                  scales: {
                    x: {
                      beginAtZero: false,
                      ticks: {
                        callback: (value, index, ticks) => {
                          return formatDateShort(data.index[index]);
                        },
                      },
                    },
                  },
                }}
              />
            </CardBody>
          </Card>
        </>
      </AutoColumns>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select tw-w-[300px]"
            value={selectedPeriodOption}
            onChange={setSelectedPeriodOption}
            options={periodOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {params.product}
              <div className="tw-text-lg">As of Date: {selectedPeriodOption.label}</div>
            </CardHeader>
            <CardBody>
              <TightChart
                title={params.product}
                data={data}
                cols={[selectedPeriodOption.value]}
                dataType="decimal"
                flip
              />
            </CardBody>
          </Card>
        </>
      </AutoColumns>
    </>
  );
}

export default LeverageLeverageIlliquidity;
