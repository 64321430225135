import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Button, Card, CardHeader, CardBody, CardFooter, Label, Row, Col, FormGroup, Form, Input } from 'reactstrap';
import Select from 'react-select';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { toDeciTable, toPercTable, formatDate, isEmptyTightData, removeEmptyRows } from 'helpers/formatter';
import { parseSearchParams } from 'helpers/location';
import { riskMeasuresPerc, riskMeasuresAll, riskRatioGroups } from 'helpers/meta';
import { allowZeroHundred } from 'helpers/number';
import { getAdvancedAnalyticsPortfolioImpactOptions, getAdvancedAnalyticsPortfolioImpact } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const StyledLabel = styled(Label)`
  font-size: 1rem !important;
`;

function SingleAssetImpactAnalysis({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState(null);
  const [impact1, setImpact1] = useState(10);
  const [impact2, setImpact2] = useState(20);
  const [impact3, setImpact3] = useState(30);
  const [result, setResult] = useState(null);
  const [durations, setDurations] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [selectedRiskGroup, setSelectedRiskGroup] = useState(riskRatioGroups[0]);
  const [selectedMeasure, setSelectedMeasure] = useState(optionize(riskMeasuresAll[0]));

  const fetchOptions = async (params) => {
    const [data, err] = await getAdvancedAnalyticsPortfolioImpactOptions();
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      const opts = data.filter((v) => v !== params.product);
      setOptions(optionizeAll(opts));
      if (!option) setOption(optionize(opts[0]));
    }
  };

  const fetchResult = async () => {
    if (!params) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsPortfolioImpact({
      ...params,
      portfolio: option.value,
      impact1,
      impact2,
      impact3,
    });
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      data.risk_summary = _.omitBy(data.risk_summary, isEmptyTightData);
      data.risk_summary = _.mapValues(data.risk_summary, removeEmptyRows);
      setResult(data);
      const durs = _.keys(data.risk_summary);
      setDurations(optionizeAll(durs, { labelFormatter: _.toUpper }));
      if (durs.length > 0) setSelectedDuration(optionize(durs[0], _.toUpper(durs[0])));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (options.length === 0) fetchOptions(params);
  }, [params]);

  useEffect(() => {
    if (params && option) fetchResult();
  }, [option]);

  if (loading || !result) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <AssetInfo
        asset={params.product}
        database={params.database}
        data={result}
        valueMap={{
          name: 'info.Fund Name',
          description: 'info.Fund Description',
          benchmark: 'info.Benchmark Name',
          geography: 'info.geography',
          startDate: 'info.Dates.0',
          endDate: 'info.Dates.1',
          cumulative: 'info.cumul_return',
          cagr: 'info.cagr',
          deviation: 'info.std',
          impactDescription: 'info.impact_portfolio_descr',
        }}
        showMap={{
          name: true,
          description: false,
          benchmark: false,
          geography: false,
          impactDescription: true,
          timePeriod: true,
          returns: false,
        }}
      >
        <Card>
          <CardBody className="mt-3">
            <Form className="form-horizontal">
              <Row className="mb-1">
                <Col sm="3" className="text-right">
                  <StyledLabel>Portfolio Selection</StyledLabel>
                </Col>
                <Col sm="7">
                  <FormGroup className="mb-0">
                    {options && (
                      <Select
                        className="react-select"
                        name="portfolio"
                        value={option}
                        onChange={setOption}
                        options={options}
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col sm="3" className="text-right">
                  <StyledLabel>Subject Impact 1 (%)</StyledLabel>
                </Col>
                <Col sm="7">
                  <FormGroup className="mb-0">
                    <Input
                      type="number"
                      min={1}
                      max={99}
                      value={impact1}
                      onChange={(event) => {
                        setImpact1(allowZeroHundred(event.target.value));
                      }}
                      onWheel={(event) => {
                        event.target?.blur();
                        event.currentTarget?.blur();
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col sm="3" className="text-right">
                  <StyledLabel>Subject Impact 2 (%)</StyledLabel>
                </Col>
                <Col sm="7">
                  <FormGroup className="mb-0">
                    <Input
                      type="number"
                      min={1}
                      max={99}
                      value={impact2}
                      onChange={(event) => {
                        setImpact2(allowZeroHundred(event.target.value));
                      }}
                      onWheel={(event) => {
                        event.target?.blur();
                        event.currentTarget?.blur();
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col sm="3" className="text-right">
                  <StyledLabel>Subject Impact 3 (%)</StyledLabel>
                </Col>
                <Col sm="7">
                  <FormGroup className="mb-0">
                    <Input
                      type="number"
                      min={1}
                      max={99}
                      value={impact3}
                      onChange={(event) => {
                        setImpact3(allowZeroHundred(event.target.value));
                      }}
                      onWheel={(event) => {
                        event.target?.blur();
                        event.currentTarget?.blur();
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardFooter className="mt-0 text-center">
            <Button className="btn-round" color="info" onClick={fetchResult}>
              Analyze
            </Button>
          </CardFooter>
        </Card>
      </AssetInfo>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Returns
          <div className="small">As of: {formatDate(result.info.Dates[1])}</div>
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="returns"
            data={result.return_summary}
            indexName="Time Interval"
            // indexWidth="250px"
            cellFormatter={(value, row, col) => (['Inception Date'].includes(col) ? value : toPercTable(value))}
            rowDecorator={(rowTitle, rowInd, rowTitles) =>
              rowTitle === option.value ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
            }
            title={['Returns', `As of: ${formatDate(result.info.Dates[1])}`]}
          />
        </CardBody>
      </Card>
      {durations && durations.length > 0 && (
        <>
          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select mw-200px"
            name="duration"
            value={selectedDuration}
            onChange={setSelectedDuration}
            options={durations}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Metrics
              <div className="small">Time Period ({selectedDuration.value.replace('yr', ' Year')})</div>
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="risk-summary"
                data={result.risk_summary[selectedDuration.value]}
                indexName=""
                useSort={true}
                cellFormatter={(value, row, col) =>
                  riskMeasuresPerc.includes(col) ? toPercTable(value) : toDeciTable(value)
                }
                rowDecorator={(rowTitle, rowInd, rowTitles) =>
                  rowTitle === option.value ? { style: { backgroundColor: 'rgb(251,198,88,0.5)' } } : undefined
                }
                compact
                title={['Risk Metrics', `Time Period (${selectedDuration.value.replace('yr', ' Year')})`]}
              />
            </CardBody>
          </Card>
        </>
      )}
      <AutoColumns>
        <div>
          <div className="tw-text-left">
            <div className="tw-text-left tw-font-bold">SELECT RISK METRICS</div>
            <Select
              className="react-select tw-w-[290px] tw-inline-block"
              name="riskGroup"
              value={selectedRiskGroup}
              onChange={setSelectedRiskGroup}
              options={riskRatioGroups}
            />
          </div>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Risk Charts
            </CardHeader>
            <CardBody>
              <TightChart
                title="Risk Charts"
                data={result.risk_summary[selectedDuration.value]}
                cols={selectedRiskGroup.value.keys}
                base="cols"
                dataType={selectedRiskGroup.value.type}
              />
            </CardBody>
          </Card>
        </div>
        <div>
          <div className="tw-text-left">
            <div className="tw-text-left tw-font-bold">SELECT METRIC</div>
            <Select
              className="react-select tw-w-[290px] tw-inline-block"
              name="measure"
              value={selectedMeasure}
              onChange={setSelectedMeasure}
              options={optionizeAll(riskMeasuresAll)}
            />
          </div>
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {selectedMeasure.label}
            </CardHeader>
            <CardBody>
              <TightChart
                title={selectedMeasure.label}
                data={result.risk_summary[selectedDuration.value]}
                cols={[selectedMeasure.value]}
                dataType={riskMeasuresPerc.includes(selectedMeasure.value) ? 'percentage' : 'decimal'}
                wraplabelX={true}
              />
            </CardBody>
          </Card>
        </div>
      </AutoColumns>
    </>
  );
}

export default SingleAssetImpactAnalysis;
