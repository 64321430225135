import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, Label, TabPane, Row, Col } from 'reactstrap';
import Select from 'react-select';
import PageTabs from 'components/PageTabs';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import AssetInfo from './AssetInfo';
import { optionize, optionizeAll } from 'helpers/select';
import { getTightColIndexByKey } from 'helpers/chart';
import { toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { parseSearchParams } from 'helpers/location';
import { getAdvancedAnalyticsFactorExposuresOptions, getAdvancedAnalyticsFactorExposures } from 'services/analysis';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

function SingleAssetFactorAnalysis({ params, notify, session }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [factors, setFactors] = useState([]);
  const [selectedFactor, setSelectedFactor] = useState(null);
  const [factorGroups, setFactorGroups] = useState([]);
  const [selectedFactorGroup, setSelectedFactorGroup] = useState(null);
  const [durations1, setDurations1] = useState();
  const [selectedDuration1, setSelectedDuration1] = useState();
  const [durations2, setDurations2] = useState();
  const [selectedDuration2, setSelectedDuration2] = useState();
  const [groupFactors, setGroupFactors] = useState();
  const [selectedGroupFactor, setSelectedGroupFactor] = useState();
  const [currentTab, setCurrentTab] = useState({ value: 'single' });

  const fetchOptions = async () => {
    const [data, err] = await getAdvancedAnalyticsFactorExposuresOptions();
    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      const { factors, factor_groups } = data;

      setFactors(optionizeAll(factors));
      if (!selectedFactor) setSelectedFactor(optionize(factors[0]));
      setFactorGroups(optionizeAll(factor_groups));
      if (!selectedFactorGroup) setSelectedFactorGroup(optionize(factor_groups[0]));
    }
  };

  const fetchResult = async () => {
    if (!params || !selectedFactor || !selectedFactorGroup) return;

    setLoading(true);
    const [data, err] = await getAdvancedAnalyticsFactorExposures({
      ...params,
      single_factor_choice: selectedFactor.value,
      factor_group_choice: selectedFactorGroup.value,
    });

    if (err) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setResult(data);

      const durs1 = data.single_factor_tab.asset_df.columns;
      const _durationOptions1 = optionizeAll(durs1, { labelFormatter: _.upperFirst });
      setDurations1(_durationOptions1);
      if (_durationOptions1.length > 0) setSelectedDuration1(_durationOptions1[0]);

      const durs2 = Object.keys(data.group_factor_tab);
      const _durationOptions2 = optionizeAll(durs2, { labelFormatter: _.upperFirst });
      setDurations2(_durationOptions2);
      if (_durationOptions2.length > 0) setSelectedDuration2(_durationOptions2[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOptions();
  }, [params]);

  useEffect(() => {
    fetchResult();
  }, [factors, factorGroups, selectedFactor, selectedFactorGroup]);

  useEffect(() => {
    if (!result || !selectedDuration2) return;

    const keys = result.group_factor_tab[selectedDuration2.value][0].columns;
    setGroupFactors(optionizeAll(keys));
    if (keys.length > 0) setSelectedGroupFactor(optionize(keys[0]));
  }, [result, selectedDuration2]);

  const comparisonDf1 = useMemo(() => {
    if (!result) return null;

    const { single_factor_tab } = result;
    const { asset_df, bench_df } = single_factor_tab;
    const colIndex = getTightColIndexByKey(asset_df, selectedDuration1.value);

    return {
      index: asset_df.index,
      columns: [params.product, _.get(result, 'info.Benchmark Name')],
      data: asset_df.index.map((rowName, i) => {
        return [asset_df.data[i][colIndex], bench_df.data[i][colIndex]];
      }),
    };
  }, [result, selectedDuration1?.value]);

  const comparisonDf2 = useMemo(() => {
    if (!result || !selectedDuration2 || !selectedGroupFactor) return null;

    const { group_factor_tab } = result;
    const [asset_df, bench_df] = group_factor_tab[selectedDuration2.value];
    const colIndex = getTightColIndexByKey(asset_df, selectedGroupFactor.value);

    return {
      index: asset_df.index,
      columns: [params.product, _.get(result, 'info.Benchmark Name')],
      data: asset_df.index.map((rowName, i) => {
        return [asset_df.data[i][colIndex], bench_df.data[i][colIndex]];
      }),
    };
  }, [result, selectedDuration2?.value, selectedGroupFactor?.value]);

  if (loading || !result || !factors || !factorGroups || !comparisonDf1 || !comparisonDf2) {
    return (
      <SpinnerWrapper>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapper>
    );
  }

  const factorDescription = _.get(result, 'info.Factor Description');
  const groupDescription = _.get(result, 'info.Factor Group Description');

  return (
    <>
      <AssetInfo
        asset={params.product}
        database={params.database}
        data={result}
        valueMap={{
          name: 'info.Fund Name',
          description: 'info.Fund Description',
          benchmark: 'info.Benchmark Name',
          startDate: 'info.Dates.0',
          endDate: 'info.Dates.1',
          cumulative: 'info.cumul_return',
          cagr: 'info.cagr',
          deviation: 'info.std',
        }}
      />
      <PageTabs
        options={[
          {
            value: 'single',
            label: 'Single Factors',
            default: currentTab.value === 'single',
            permission: 'aa-factor-exposures-single-factor',
          },
          {
            value: 'group',
            label: 'Factor Groups',
            default: currentTab.value === 'group',
            permission: 'aa-factor-exposures-factor-group',
          },
        ]}
        onTabSelect={setCurrentTab}
        session={session}
      >
        <TabPane tabId="single" role="tabpanel">
          <div className="tw-text-left tw-font-bold">SELECT FACTOR EXPOSURE</div>
          <Select
            className="react-select mw-400px"
            name="factors"
            value={selectedFactor}
            onChange={setSelectedFactor}
            options={factors}
          />

          <Card>
            <CardBody className="h5">
              <Row className="mb-2">
                <Col sm="3" className="text-right">
                  <Label>Factor Description</Label>
                </Col>
                <Col sm="7">{factorDescription}</Col>
              </Row>
            </CardBody>
          </Card>

          {selectedFactor && (
            <>
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Exposure: {selectedFactor.label}
                  <TimePeriod dates={result.single_factor_tab.date_range} />
                </CardHeader>
                <CardBody>
                  <TightTable
                    suffix="single-factor"
                    data={result.single_factor_tab.asset_df}
                    rowHeader={{ text: params.product, style: { color: 'black', backgroundColor: '#C6E0B4' } }}
                    indexName="Factor"
                    cellFormatter={(value, row, col) =>
                      ['Beta', 'Correlation', 't-stat'].includes(row)
                        ? toDeciTable(value)
                        : row === 'R2'
                        ? toPercTable(value)
                        : value
                    }
                    compact
                    title={['Drawdown Detail', formatDates(result.single_factor_tab.date_range)]}
                  />
                </CardBody>
              </Card>

              <Row>
                <Col xl="8" className="offset-xl-2">
                  <Card>
                    <CardHeader tag="h4" className="mt-0 text-center">
                      Exposure: {selectedFactor.label}
                    </CardHeader>
                    <CardBody>
                      <TightChart
                        title={`Exposure: ${selectedFactor.label}`}
                        data={result.single_factor_tab.asset_df}
                        rows={['Beta', 'R2']}
                        base="cols"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select mw-200px"
            name="duration1"
            value={selectedDuration1}
            onChange={setSelectedDuration1}
            options={durations1}
          />

          <Row>
            <Col xl="6">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedFactor.label}
                </CardHeader>
                <CardBody>
                  <TightTable
                    suffix="factor-analysis"
                    data={comparisonDf1}
                    indexName=""
                    cellFormatter={(value, row, col) =>
                      ['Beta', 'Correlation', 't-stat'].includes(row)
                        ? toDeciTable(value)
                        : row === 'R2'
                        ? toPercTable(value)
                        : value
                    }
                    compact
                    title={selectedFactor.label}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedFactor.label}
                </CardHeader>
                <CardBody>
                  <TightChart title={selectedFactor.label} data={comparisonDf1} rows={['Beta', 'R2']} base="cols" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="group" role="tabpanel">
          <div className="tw-text-left tw-font-bold">SELECT FACTOR GROUP</div>
          <Select
            className="react-select mw-400px"
            name="factors"
            value={selectedFactorGroup}
            onChange={setSelectedFactorGroup}
            options={factorGroups}
          />
          <Card>
            <CardBody className="h5">
              <Row className="mb-2">
                <Col sm="3" className="text-right">
                  <Label>Factor Group Description</Label>
                </Col>
                <Col sm="7">{groupDescription}</Col>
              </Row>
            </CardBody>
          </Card>

          <div className="tw-text-left tw-font-bold">SELECT TIME PERIOD</div>
          <Select
            className="react-select mw-200px"
            name="duration2"
            value={selectedDuration2}
            onChange={setSelectedDuration2}
            options={durations2}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              Exposure: {selectedFactorGroup.label}
              <TimePeriod dates={result.group_factor_tab[selectedDuration2.value][2]} />
            </CardHeader>
            <CardBody>
              <TightTable
                suffix="group-factor"
                data={result.group_factor_tab[selectedDuration2.value][0]}
                indexName="Time Interval"
                cellFormatter={(value, row, col) =>
                  ['Beta', 'Correlation', 't-stat'].includes(row)
                    ? toDeciTable(value)
                    : row === 'R2'
                    ? toPercTable(value)
                    : value
                }
                compact
                title={[
                  `Exposure: ${selectedFactorGroup.label}`,
                  formatDates(result.group_factor_tab[selectedDuration2.value][2]),
                ]}
              />
            </CardBody>
          </Card>
          <Row>
            <Col xl="8" className="offset-xl-2">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  Exposure: {selectedFactorGroup.label}
                </CardHeader>
                <CardBody>
                  <TightChart
                    title={`Exposure: ${selectedFactorGroup.label}`}
                    data={result.group_factor_tab[selectedDuration2.value][0]}
                    rows={['Beta', 'R2']}
                    base="cols"
                    wraplabelX
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="tw-text-left tw-font-bold">SELECT FACTOR EXPOSURE</div>
          <Select
            className="react-select mw-400px"
            name="groupFactor"
            value={selectedGroupFactor}
            onChange={setSelectedGroupFactor}
            options={groupFactors}
          />
          <Row>
            <Col xl="6">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedGroupFactor.label} Exposure
                </CardHeader>
                <CardBody>
                  <TightTable
                    suffix="factor-analysis-exposure"
                    data={comparisonDf2}
                    indexName=""
                    cellFormatter={(value, row, col) =>
                      ['Beta', 'Correlation', 't-stat'].includes(row)
                        ? toDeciTable(value)
                        : row === 'R2'
                        ? toPercTable(value)
                        : value
                    }
                    compact
                    title={`${selectedGroupFactor.label} Exposure`}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedGroupFactor.label} Exposure
                </CardHeader>
                <CardBody>
                  <TightChart
                    title={`${selectedGroupFactor.label} Exposure`}
                    data={comparisonDf2}
                    rows={['Beta', 'R2']}
                    base="cols"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </PageTabs>
    </>
  );
}

export default SingleAssetFactorAnalysis;
