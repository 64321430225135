import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toPerc, formatDateShort, formatCurrency } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function LeverageLeverageIlliquidity({ data, params, notify, session }) {
  const [dateOptions, setDateOptions, selectedDateOption, setSelectedDateOption] = useArrayState({
    initialItems: optionizeAll(_.orderBy(_.keys(data), [], ['desc']), { labelFormatter: formatDateShort }),
  });
  const [subjectOptions, setSubjectOptions, selectedSubjectOption, setSelectedSubjectOption] = useArrayState();
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    if (!selectedDateOption) return;

    const _data = data[selectedDateOption.value];

    const _subjects = _data.columns.filter((col, ind) => {
      return _data.data.some((item) => item[ind]);
    });

    const _subjectsOptions = optionizeAll(_subjects);
    setSubjectOptions(_subjectsOptions);

    const _period = _data.index.filter((v) => v !== 'Currency');
    const _periodOptions = optionizeAll(_period);
    setPeriodOptions(_periodOptions);
  }, [selectedDateOption]);

  if (!selectedSubjectOption || !selectedPeriodOption) {
    return null;
  }

  return (
    <>
      <div className="tw-text-left tw-font-bold">SELECT DATE</div>
      <Select
        className="react-select tw-w-[300px]"
        value={selectedDateOption}
        onChange={setSelectedDateOption}
        options={dateOptions}
      />
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Leverage & Illiquid Assets
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="aum"
            data={data[selectedDateOption.value]}
            indexFormatter={formatDateShort}
            cellFormatter={(value, row, col) => {
              if (row === 'Currency') return value;
              if (row === 'Leverage') return toDeci(value);

              return toPerc(value, { precision: 0 });
            }}
            title="Leverage & Illiquid Assets"
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT FUND</div>
          <Select
            className="react-select tw-w-[400px] tw-mb-1"
            value={selectedSubjectOption}
            onChange={setSelectedSubjectOption}
            options={subjectOptions}
          />
          <div className="tw-text-left tw-font-bold">SELECT DATE</div>
          <Select
            className="react-select tw-w-[300px]"
            value={selectedDateOption}
            onChange={setSelectedDateOption}
            options={dateOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {selectedSubjectOption.label}
            </CardHeader>
            <CardBody>
              <TightChart
                title={selectedSubjectOption.label}
                data={data[selectedDateOption.value]}
                cols={[selectedSubjectOption.value]}
                rows={(rows) => rows.filter((v) => v !== 'Currency')}
                dataType="decimal"
              />
            </CardBody>
          </Card>
        </>
      </AutoColumns>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select tw-w-[300px] tw-mb-1"
            value={selectedPeriodOption}
            onChange={setSelectedPeriodOption}
            options={periodOptions}
          />
          <div className="tw-text-left tw-font-bold">SELECT DATE</div>
          <Select
            className="react-select tw-w-[300px]"
            value={selectedDateOption}
            onChange={setSelectedDateOption}
            options={dateOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              {selectedPeriodOption.label}
            </CardHeader>
            <CardBody>
              <TightChart
                title={selectedPeriodOption.label}
                data={data[selectedDateOption.value]}
                cols={[selectedPeriodOption.value]}
                dataType={selectedPeriodOption.value === 'Leverage' ? 'decimal' : 'percentage'}
                flip
                alwaysShowScale
                wraplabelX
              />
            </CardBody>
          </Card>
        </>
      </AutoColumns>
    </>
  );
}

export default LeverageLeverageIlliquidity;
