import React, { useContext, useState, useEffect, useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import { Table, UncontrolledTooltip } from 'reactstrap';
import { getHeatmap, distinctColor } from 'helpers/color';
import {
  toDeci,
  toPerc,
  toDeciTable,
  toPercTable,
  formatCurrency,
  formatDate,
  isEmptyTightData,
  removeEmptyRows,
} from 'helpers/formatter';
import { flipDF, filterDF } from 'helpers/data-frame';
import { downloadTable } from 'helpers/table-csv';
import { AdminContext } from 'layouts/Admin';

const cellFormatters = {
  percentage: toPercTable,
  decimal: toDeciTable,
  currency: formatCurrency,
  text: (v) => v,
};

const SORT_ORDERS = ['none', 'asc', 'desc'];
const TRUNCATE_MAX = 30;

const Container = styled.div`
  display: flex;

  & > *:nth-child(1) {
    flex-basis: ${(props) => props.indexWidth || '300px'};
    flex-grow: 1;
    flex-shrink: 0;
    overflow: hidden;
  }

  & > *:nth-child(2) {
    border-left: 1px solid rgb(222, 226, 230);
  }
`;

const StyledTable = styled(Table)`
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: ${(props) => (props.compact ? 'flex' : 'table')} !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;

  & > thead {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & > tr > th {
      white-space: break-spaces;
      font-size: 0.8rem;
    }
  }

  & > tbody {
    -webkit-box-flex: 99999;
    -ms-flex: 99999 1 auto;
    flex: 99999 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;

    & tr td {
      ${(props) => `font-weight: ${props.bold ? '700' : '400'};`}
      font-size: 0.9rem;
    }
  }

  & tr {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

    // & th:first-child,
    // & td:first-child {
    //   flex-basis: 200px;
    //   flex-grow: 1;
    //   flex-shrink: 0;
    // }

    & th,
    & td {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
      flex: 1 0 0;
      text-overflow: ellipsis;
      padding: 12px 7px;
      overflow: hidden;
      transition: 0.3s ease;
      transition-property: width, min-width, padding, opacity;
      line-height: 2;
    }

    & td {
      ${(props) => props.cellWidth && `width: ${props.cellWidth};`}
      white-space: nowrap;
    }

    & th {
      border-top: 1px solid rgb(222, 226, 230) !important;
      border-left: 1px solid rgb(222, 226, 230) !important;
      border-right: 1px solid rgb(222, 226, 230) !important;
    }
  }

  .overflow-x-hidden {
    overflow-x: hidden !important;
  }
`;

const TruncateTooltip = ({ id, value } = {}) => {
  return (
    <>
      <span id={id}>{_.truncate(value, { length: TRUNCATE_MAX })}</span>
      {value.length > TRUNCATE_MAX && (
        <UncontrolledTooltip delay={0} target={id}>
          {value}
        </UncontrolledTooltip>
      )}
    </>
  );
};

const Tooltip = ({ id, value } = {}) => {
  if (_.isBoolean(value)) {
    return <>{value ? 'True' : 'False'}</>;
  }

  if (_.isNil(value) || (_.isString(value) && _.isEmpty(value))) {
    return <>&nbsp;</>;
  }

  return (
    <>
      <span id={id}>{value}</span>
      <UncontrolledTooltip delay={0} target={id} placement="bottom-start">
        {value}
      </UncontrolledTooltip>
    </>
  );
};

const IndexCell = ({ id, value }) => {
  let className = '';
  let style = {};

  if (_.isObject(value)) {
    className = value.className;
    style = value.style;
    value = value.value;
  }

  return (
    <td className={className} style={{ whiteSpace: 'nowrap', textAlign: 'left', ...style }}>
      <Tooltip id={id} value={value} />
    </td>
  );
};

const Rows = ({
  omitEmptyRow,
  rowHeader,
  rowDecorator,
  indexFormatter,
  cellFormatter,
  tightDict,
  heatmap = {},
  role = 'left',
  suffix = '',
  flip = false,
}) => {
  if (!tightDict) return null;
  const { column_names, columns, data, index, index_names } = tightDict;

  let header = null;
  if (rowHeader) {
    const headerProps = {};
    let headerValue = null;

    if (_.isObject(rowHeader)) {
      headerProps.className = rowHeader.className;
      headerProps.style = rowHeader.style;
      headerValue = rowHeader.text;
    } else {
      headerValue = rowHeader;
    }

    if (role === 'left') {
      headerValue = _.trim(headerValue) ? <>&nbsp;</> : <></>;
    }

    header = (
      <tr key={`${suffix}-row-header`} {...headerProps}>
        <td colSpan={columns.length + 1} class="text-center">
          {headerValue}
        </td>
      </tr>
    );
  }

  const rows = [];

  _.each(index, (rowTitle, rowInd) => {
    const { className = '', style = {} } = rowDecorator(rowTitle, rowInd, index) || {};

    let empty = true;
    const row = (
      <tr key={`${suffix}-${rowInd}`} className={className} style={style}>
        {role === 'left' ? (
          <IndexCell id={`cell-${suffix}-${rowInd}-`} value={indexFormatter ? indexFormatter(rowTitle) : rowTitle} />
        ) : (
          _.map(columns, (colTitle, colInd) => {
            const value = data[rowInd][colInd];
            if (empty) empty = !value;
            const rawVal = value;
            const backgroundColor = heatmap[rawVal];
            const extraStyle = {};

            if (backgroundColor) {
              extraStyle.backgroundColor = _.isNumber(rawVal) ? backgroundColor : 'white';
              extraStyle.color = rawVal < 0 ? 'red' : distinctColor(backgroundColor);
            }

            const processed = cellFormatter(value, rowTitle, colTitle, rowInd, colInd, flip);

            if (_.isObject(processed)) {
              const { className = '', style = {}, value = '' } = processed;
              return (
                <td
                  key={colInd}
                  className={classnames(className, {
                    'text-center': colInd >= 0,
                  })}
                  style={{ ...style, ...extraStyle }}
                >
                  <Tooltip id={`cell-${suffix}-${rowInd}-${colInd}`} value={value} />
                </td>
              );
            } else {
              return (
                <td
                  key={colInd}
                  className={classnames(className, {
                    'text-center': colInd >= 0,
                  })}
                  style={{ ...extraStyle }}
                >
                  <Tooltip id={`cell-${suffix}-${rowInd}-${colInd}`} value={processed} />
                </td>
              );
            }
          })
        )}
      </tr>
    );

    if (role === 'right' && omitEmptyRow) {
      if (!empty) rows.push(row);
    } else {
      rows.push(row);
    }
  });

  if (header) rows.unshift(header);

  return rows;
};

const sortDataFrame = (df, sortBy, sortOrder) => {
  const { index, columns, data } = df;

  const sortTargets = [];
  _.each(index, (rowTitle, rowInd) => {
    if (sortBy === 0) sortTargets.push({ value: rowTitle, rowInd });
    else sortTargets.push({ value: data[rowInd][sortBy - 1], rowInd });
  });

  const orderedTargets = _.orderBy(sortTargets, 'value', [sortOrder]);

  let i = 0;
  const newIndex = _.sortBy(index, () => {
    const ind = orderedTargets.findIndex((v) => v.rowInd === i);
    i++;
    return ind;
  });

  i = 0;
  const newData = _.sortBy(data, () => {
    const ind = orderedTargets.findIndex((v) => v.rowInd === i);
    i++;
    return ind;
  });

  return {
    index: newIndex,
    data: newData,
    columns,
  };
};

function TightTable({
  data,
  data2,
  rows,
  cols,
  rows2,
  cols2,
  rowHeader,
  rowHeader2,
  displayHeaders = true,
  indexName = '',
  useSort = false,
  sortIndexes = null,
  omitEmptyRow = false,
  heatmap = false,
  rowDecorator = () => {},
  indexFormatter,
  cellFormatter,
  dataType = 'text',
  suffix = 'table',
  indexWidth = '300px',
  cellWidth = null,
  compact = false,
  flip = false,
  hideIndexTable = false,
  initialSortBy,
  initialSortOrder,
  session = {},
  title,
}) {
  const context = useContext(AdminContext);
  const leftHeader = useRef();
  const rightHeader = useRef();
  const [sortBy, setSortBy] = useState(initialSortBy ?? -1);
  const [sortOrder, setSortOrder] = useState(initialSortOrder ?? SORT_ORDERS[0]);
  let tightDict = filterDF(data, rows, cols);
  let tightDict2 = filterDF(data2, rows2, cols2);

  if (flip) {
    tightDict = flipDF(tightDict);
    tightDict2 = flipDF(tightDict2);
  }

  const { columns } = tightDict;

  if (!cellFormatter) cellFormatter = cellFormatters[dataType];

  const leftColumns = [indexName];
  const rightColumns = [...columns];

  let heat = {};
  if (heatmap) {
    const datas = [tightDict.data];
    if (tightDict2) datas.push(tightDict2.data);
    const values = _.flattenDeep(datas);

    heat = getHeatmap(values);
  }

  const sortedDataFrame = React.useMemo(() => {
    if (!tightDict || sortOrder === 'none') {
      return tightDict;
    }

    return sortDataFrame(tightDict, sortBy, sortOrder);
  }, [sortBy, sortOrder, tightDict]);

  const sortedDataFrame2 = React.useMemo(() => {
    if (!tightDict2 || sortOrder === 'none') {
      return tightDict2;
    }

    return sortDataFrame(tightDict2, sortBy, sortOrder);
  }, [sortBy, sortOrder, tightDict2]);

  const syncHeaderHeights = () => {
    if (!leftHeader.current || !rightHeader.current) return;
    const height = rightHeader.current.clientHeight;
    if (height === 0) return;

    leftHeader.current.style.height = `${height}px`;
  };

  useLayoutEffect(() => {
    syncHeaderHeights();
  }, [leftHeader.current, rightHeader.current, sortBy, sortOrder]);

  useEffect(() => {
    window.addEventListener('resize', syncHeaderHeights);
    syncHeaderHeights();
    return () => window.removeEventListener('resize', syncHeaderHeights);
  }, []);

  useEffect(() => {
    syncHeaderHeights();
  }, [data, data2]);

  return (
    <>
      {context?.session?.permissions['export-table-data'] && (
        <div className="tw-text-right -tw-mt-9 tw-mb-2">
          <button
            type="button"
            className="tw-px-2 tw-py-1 tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg hover:tw-bg-gray-100 hover:tw-text-blue-700"
            onClick={() =>
              downloadTable(sortedDataFrame, sortedDataFrame2, cellFormatter, indexName, flip, suffix, title)
            }
          >
            <ArrowDownTrayIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
          </button>
        </div>
      )}
      <Container indexWidth={indexWidth}>
        {!hideIndexTable && (
          <StyledTable responsive striped bordered className="sortable" bold>
            {displayHeaders && (
              <thead>
                <tr ref={leftHeader}>
                  {_.map(leftColumns, (col, ind) => {
                    const key = ind;
                    const _sortable = useSort && (!sortIndexes || sortIndexes.includes(key));
                    return (
                      <th
                        key={key}
                        className={classnames({
                          [`-sort-${sortOrder}`]: key === sortBy,
                          'text-left': true,
                          clickable: _sortable,
                        })}
                        onClick={() => {
                          if (!_sortable) return;

                          setSortBy(key);
                          setSortOrder(SORT_ORDERS[(SORT_ORDERS.indexOf(sortOrder) + 1) % SORT_ORDERS.length]);
                        }}
                      >
                        <Tooltip id={`${suffix}-1-header-${key}`} value={col} />
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}

            <tbody>
              <>
                <Rows
                  rowDecorator={rowDecorator}
                  indexFormatter={indexFormatter}
                  tightDict={sortedDataFrame}
                  rowHeader={rowHeader}
                  omitEmptyRow={omitEmptyRow}
                  suffix={`${suffix}-1-1`}
                  role="left"
                  flip={flip}
                />
                <Rows
                  rowDecorator={rowDecorator}
                  indexFormatter={indexFormatter}
                  tightDict={sortedDataFrame2}
                  rowHeader={rowHeader2}
                  omitEmptyRow={omitEmptyRow}
                  suffix={`${suffix}-1-2`}
                  role="left"
                  flip={flip}
                />
              </>
            </tbody>
          </StyledTable>
        )}

        <StyledTable responsive striped bordered className="sortable" bold compact={compact} cellWidth={cellWidth}>
          {displayHeaders && (
            <thead>
              <tr ref={rightHeader}>
                {_.map(rightColumns, (col, ind) => {
                  const key = ind + 1;
                  const _sortable = useSort && (!sortIndexes || sortIndexes.includes(key));
                  return (
                    <th
                      key={key}
                      className={classnames({
                        [`-sort-${sortOrder}`]: key === sortBy,
                        'text-center': true,
                        clickable: _sortable,
                      })}
                      onClick={() => {
                        if (!_sortable) return;

                        setSortBy(key);
                        setSortOrder(SORT_ORDERS[(SORT_ORDERS.indexOf(sortOrder) + 1) % SORT_ORDERS.length]);
                      }}
                    >
                      <Tooltip id={`${suffix}-2-header-${key}`} value={col} />
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}

          <tbody className="tw-overflow-x-hidden overflow-x-hidden">
            <>
              <Rows
                rowDecorator={rowDecorator}
                cellFormatter={cellFormatter}
                tightDict={sortedDataFrame}
                rowHeader={rowHeader}
                omitEmptyRow={omitEmptyRow}
                heatmap={heat}
                suffix={`${suffix}-2-1`}
                role="right"
                flip={flip}
              />
              <Rows
                rowDecorator={rowDecorator}
                cellFormatter={cellFormatter}
                tightDict={sortedDataFrame2}
                rowHeader={rowHeader2}
                omitEmptyRow={omitEmptyRow}
                heatmap={heat}
                suffix={`${suffix}-2-2`}
                role="right"
                flip={flip}
              />
            </>
          </tbody>
        </StyledTable>
      </Container>
    </>
  );
}

export default TightTable;
