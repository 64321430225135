/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Collapse } from 'reactstrap';
import styled from 'styled-components';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import classnames from 'classnames';
import smallIcon from 'assets/img/alts.png';
import altsHeader from 'assets/img/alts-header.png';

const LogoContainer = styled.div`
  padding: 0 !important;

  & .logo-normal {
    padding: 0 !important;

    & img {
      height: 75px;
    }
  }

  & .logo-mini {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;

    & img {
      margin: auto;
      max-height: 80px;
    }
  }
`;

var ps;

function Sidebar(props) {
  const { session } = props;

  const { name, email, image, permissions } = session || {};
  const location = useLocation();
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });

    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }

      if (prop.permission) {
        if (!permissions[prop.permission]) return null;
      }

      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !collapseStates[prop.state];
        return (
          <li className={getCollapseInitialState(prop.views) ? '_' : ''} key={key}>
            <a
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p className="tw-text-[#B20537] tw-select-none">
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal tw-text-[#B20537] tw-select-none">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeclassname="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p className="tw-text-[#B20537]">{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal tw-text-[#B20537]">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? 'tw-underline tw-decoration-[#B20537]' : '';
  };

  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  }, []);

  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <LogoContainer className="logo">
        <a href="/" className="simple-text logo-mini">
          <img src={smallIcon} width="100%" alt="alts-logo" />
        </a>
        <a href="/" className="simple-text logo-normal">
          <img src={altsHeader} width="100%" alt="alts-logo" />
        </a>
      </LogoContainer>

      <div className="sidebar-wrapper tw-bg-[#A9A9A9]" ref={sidebar}>
        {session && (
          <div className="tw-text-white tw-mt-3 tw-px-3 tw-text-center tw-cursor-default username">
            <span>{name}</span>
          </div>
        )}

        {session && <Nav>{createLinks(props.routes)}</Nav>}
      </div>
    </div>
  );
}

export default Sidebar;
