import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import bg from 'assets/img/analyzers-bg.png';
import ia from 'assets/img/analyzers-ia.png';
import ic from 'assets/img/analyzers-ic.png';
import pa from 'assets/img/analyzers-pa.png';

const Background = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  border-radius: 0.25rem;
`;

function Analyzers({ session }) {
  const navigate = useNavigate();
  const { permissions } = session ?? {};

  return (
    <div className="tw-max-w-7xl tw-m-auto tw-mt-10">
      <Background>
        <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-flex-wrap tw-px-3 tw-py-12 lg:tw-flex-row tw-cursor-pointer">
          {permissions.ia && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 xl:tw-w-1/3 hover:tw-underline tw-text-[#B20537]"
              onClick={() =>
                navigate({ pathname: '/analyzers/asset-discovery-ia', search: 'minass=true' }, { replace: false })
              }
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-font-bold tw-leading-tight">
                Fund
                <br />
                Analysis
              </h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[210px] tw-mb-2"
                src={ia}
                alt="Discovery"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px] tw-text-[#fff]">
                Comprehensive quantitative risk analysis of a single alternative investment fund.
              </p>
            </div>
          )}

          {permissions.ic && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 xl:tw-w-1/3 hover:tw-underline tw-text-[#B20537]"
              onClick={() =>
                navigate(
                  { pathname: '/analyzers/asset-discovery-ic', search: 'multi=true&minass=true' },
                  { replace: false },
                )
              }
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-text-[#B20537] tw-font-bold tw-leading-tight">
                Fund
                <br />
                Comparison
              </h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[210px] tw-mb-2"
                src={ic}
                alt="Discovery"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px] tw-text-[#fff]">
                Comprehensive quantitative risk analysis and comparison of multiple alternative investment funds.
              </p>
            </div>
          )}

          {permissions.pa && (
            <div
              className="tw-mb-5 tw-w-full tw-px-3 xl:tw-w-1/3 hover:tw-underline tw-text-[#B20537]"
              onClick={() => navigate({ pathname: '/analyzers-pa' }, { replace: false })}
            >
              <h3 className="tw-w-full tw-text-center tw-text-3xl tw-text-[#B20537] tw-font-bold tw-leading-tight">
                Portfolio
                <br />
                Analysis
              </h3>
              <img
                className="tw-mx-auto tw-w-full tw-max-w-[220px] tw-max-h-[210px] tw-mb-2"
                src={pa}
                alt="Discovery"
              />
              <p className="tw-mt-3 tw-text-2xl tw-mx-auto tw-max-w-[300px] tw-text-[#fff]">
                Design, analyze and compare portfolios of alternative investment funds. Option to include generic asset
                classes for testing purposes.
              </p>
            </div>
          )}
        </div>
      </Background>
    </div>
  );
}

export default Analyzers;
