export const riskMeasuresRatios = ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'];
export const riskMeasuresPerc0 = ['Standard Deviation', 'CVaR 95%', 'Maximum Drawdown'];
export const riskMeasuresPerc = ['Cumulative Return', 'CAGR', ...riskMeasuresPerc0];
export const riskMeasuresDeci = ['Beta', 'Skew', 'Kurtosis', ...riskMeasuresRatios, 'Correlation'];
export const riskMeasuresDeci2 = [...riskMeasuresDeci, 'MAR Ratio (RoMaD)'];
export const riskMeasuresAll = [...riskMeasuresPerc, ...riskMeasuresDeci];

export const riskRatioGroups = [
  {
    label: 'CAGR and Std Dev',
    value: { keys: ['CAGR', 'Standard Deviation'], type: 'percentage' },
  },
  {
    label: 'Beta and Correlation',
    value: { keys: ['Beta', 'Correlation'], type: 'decimal' },
  },
  {
    label: 'CVaR and Max DD',
    value: { keys: ['CVaR 95%', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'Skew and Kurtosis',
    value: { keys: ['Skew', 'Kurtosis'], type: 'decimal' },
  },
  {
    label: 'Ratios (Sharpe, Sortino, Omega)',
    value: { keys: ['Sharpe Ratio', 'Sortino Ratio', 'Omega Ratio'], type: 'decimal' },
  },
];

export const drawdownMetrics = [
  {
    label: 'CAGR and Max DD',
    value: { keys: ['CAGR', 'Standard Deviation', 'Maximum Drawdown'], type: 'percentage' },
  },
  {
    label: 'DD and Recovery Duration',
    value: { keys: ['Drawdown Duration', 'Recovery Duration to HWM'], type: 'text' },
  },
  {
    label: 'Ratios',
    value: { keys: ['Calmar Ratio', 'MAR Ratio (RoMaD)', 'Sterling Ratio'], type: 'decimal' },
  },
];
