import { HueCircle19 } from 'helpers/colorschemes/colorschemes.tableau';

export const getTightRowByKey = (data, key) => {
  const ind = data.index.indexOf(key);
  if (ind === -1) return [];

  return data.data[ind];
};

export const getTightColIndexByKey = (data, key) => data.columns.indexOf(key);
export const getTightRowIndexByKey = (data, key) => data.index.indexOf(key);

export const getDataByColName = (data, colName) => {
  const ind = data.columns.indexOf(colName);
  if (ind === -1) return [];
  return data.data.map((row) => row[ind]);
};

export const getDataByColIndex = (data, colIndex) => {
  return data.index.map((row) => row[colIndex]);
};

// export const colors = _.shuffle(HueCircle19);

export const colors = [
  '#B20537',
  '#058EB2',
  '#A9A9A9',
  '#CB063F',
  '#047A99',
  '#B0A2A2',
  '#E40646',
  '#06A2CB',
  '#B79B9B',
  '#99042F',
  '#046680',
  '#BF9494',
  '#E27070',
];
