import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

function format(d) {
  const date = new Date(d);
  if (isNaN(date)) return d;

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${year}`;
}

export function formatDates(dates) {
  return `${format(dates[0])} - ${format(dates[1])}`;
}

function TimePeriod({ dates = [], prefix = '' }) {
  return (
    <div className="tw-text-sm">
      {prefix && <span className="tw-mr-1">{prefix}</span>}
      <span
        className={classnames({
          'tw-font-semibold': !!prefix,
        })}
      >
        {formatDates(dates)}
      </span>
    </div>
  );
}

export default TimePeriod;
