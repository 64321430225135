import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, Alert } from 'reactstrap';
import bgLanding from 'assets/files/landing.png';
import landingTitle from 'assets/files/landing-title.png';

const Container = styled.div`
  .card {
    text-align: center;
    width: 100%;
    height: 100%;
    background-image: url(${bgLanding});
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;

    & hr {
      border-top: 2px solid #4682b4;
    }
  }

  h1 {
    font-weight: 700;
  }

  .content {
    color: #b20c37;
    font-size: 2.1rem;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

function Landing({ session }) {
  const { id, groups = [] } = session || {};

  const showAlert = id && groups.length === 0;

  return (
    <Container>
      {showAlert && (
        <Alert color="info">
          <h4 className="tw-my-0 tw-mt-2">Thank you for the registration.</h4>
          <hr />
          <p className="tw-text-lg">We are reviewing your registration and assigning membership to your account.</p>
        </Alert>
      )}

      <Row>
        <Col
          xl={{
            offset: 1,
            size: 10,
          }}
          md="12"
        >
          <Card>
            <CardHeader>
              <img src={landingTitle} alt="alts-logo" className="tw-w-[300px]" />
              <CardTitle tag="h1" className="tw-text-white text-shadow-lg">
                Canadian Alternatives Hub™
              </CardTitle>
            </CardHeader>
            <CardBody>
              <hr />
              <p className="content">
                Comprehensive and centralized Canadian alternative investment fund data, intelligence and analytics for
                dedicated financial professionals, investment advisors, and institutional allocators of risk capital.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Landing;
