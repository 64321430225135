import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Spinner, TabPane, Row, Col, Label } from 'reactstrap';
import PageTabs from 'components/PageTabs';
import { getInvestmentAnalysis, getInvestmentAnalysisHeader } from 'services/analysis';
import { FixedCenterLoader } from 'components/Loaders';
import { parseSearchParams } from 'helpers/location';
import { toDeci, toPercTable, formatDate } from 'helpers/formatter';

import DetailSummary from './Detail/Summary';
import DetailAUM from './Detail/AUM';
import DetailLeverageNetAssets from './Detail/LeverageNetAssets';
import DetailLeverageLeverageIlliquidity from './Detail/LeverageLeverageIlliquidity';
import DetailFundDetail from './Detail/FundDetail';
import DetailFirmDetail from './Detail/FirmDetail';
import DetailClassDetail from './Detail/ClassDetail';
import DetailNotes from './Detail/Notes';

import RiskPerformanceReturns from './RiskPerformance/Returns';
import RiskPerformanceRiskMetrics from './RiskPerformance/RiskMetrics';
import RiskPerformanceValueAtRisk from './RiskPerformance/ValueAtRisk';
import RiskPerformanceDrawdown from './RiskPerformance/Drawdown';
import RiskPerformanceMarketRisk from './RiskPerformance/MarketRisk';
import RiskPerformanceRiskExposure from './RiskPerformance/RiskExposure';
import RiskPerformanceRiskContribution from './RiskPerformance/RiskContribution';
import RiskPerformanceFamaFrench from './RiskPerformance/FamaFrench';
import RiskPerformanceCorrelation from './RiskPerformance/Correlation';
import RiskPerformanceInterestRateRisk from './RiskPerformance/InterestRateRisk';
import RiskPerformanceCreditRisk from './RiskPerformance/CreditRisk';
import RiskPerformancePortfolioImpact from './RiskPerformance/PortfolioImpact';
import AssetHeader from 'views/AssetHeader';

function HeaderComponent({ content }) {
  if (!content) return;

  return (
    <Card>
      <CardHeader tag="h4" className="mt-0 tw-text-center">
        Description
      </CardHeader>
      <CardBody>
        <div className="tw-italic tw-text-sm tw-text-center">{content}</div>
      </CardBody>
    </Card>
  );
}

function FooterComponent({ content }) {
  if (!content) return;

  return (
    <Card>
      <CardHeader tag="h4" className="mt-0 tw-text-center">
        Notes
      </CardHeader>
      <CardBody>
        <div className="tw-italic tw-text-sm tw-text-center">{content}</div>
      </CardBody>
    </Card>
  );
}

function InvestmentAnalysis({ notify, session }) {
  const location = useLocation();
  const [params, setParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [detail, setDetail] = useState(null);
  const [riskPerf, setRiskPerf] = useState(null);

  const loadData = async () => {
    if (!params) return;

    setLoading(true);
    const [infoRes, dataRes] = await Promise.all([getInvestmentAnalysisHeader(params), getInvestmentAnalysis(params)]);
    const [info, infoErr] = infoRes;
    const [data, dataErr] = dataRes;

    if (infoErr || dataErr) {
      notify.danger({ message: <div>{err}</div> });
    } else {
      setInfo(info);
      setDetail(data[1]);
      setRiskPerf(data[0]);
    }

    setLoading(false);
  };

  useEffect(() => {
    const params = parseSearchParams(location.search, [
      'product',
      'database',
      'benchmark',
      'geography',
      'riskbucket',
      'correlation',
      'significance',
      'sdate',
      'edate',
    ]);

    setParams(params);
  }, [location]);

  useEffect(() => {
    if (params) loadData();
  }, [params]);

  if (loading || !riskPerf) {
    return <FixedCenterLoader />;
  }

  const detailKeys = _.keys(detail.data);
  const riskKeys = _.keys(riskPerf.data);

  const topTabOptions = [];
  if (detailKeys.length > 0) {
    topTabOptions.push({ value: 'detail', label: 'Detail' });
  }

  if (riskKeys.length > 0) {
    topTabOptions.push({ value: 'riskperf', label: 'Risk & Performance' });
  }

  return (
    <>
      <AssetHeader
        asset={params.product}
        database={params.database}
        tableData={{
          ..._.reduce(
            info.asset_info_header.index,
            (ret, val, ind) => {
              ret[val] = info.asset_info_header.data[ind][0];
              return ret;
            },
            {},
          ),
          'Time Period': `${info.start_date} - ${info.end_date}`,
        }}
        boxData={{
          CAGR: _.get(info, 'top metrics.CAGR', 0),
          'Standard Deviation': _.get(info, 'top metrics.Standard Deviation', 0),
          'Maximum Drawdown': _.get(info, 'top metrics.Maximum Drawdown', 0),
        }}
      />
      <PageTabs options={topTabOptions} bgColor="transparent" width="180px" maxLabelLength={21}>
        <TabPane tabId="detail" role="tabpanel">
          <PageTabs
            options={_.compact([
              detail.data['Summary'] && { value: 'summary', label: detail.titles['Summary'] },
              detail.data['AUM'] && { value: 'aum', label: detail.titles['AUM'] },
              detail.data['Leverage'] && { value: 'leverage', label: detail.titles['Leverage'] },
              detail.tables['Fund Detail'] && { value: 'fund-detail', label: detail.titles['Fund Detail'] },
              detail.tables['Firm Detail'] && { value: 'firm-detail', label: detail.titles['Firm Detail'] },
              detail.tables['Class Detail'] && { value: 'class-detail', label: detail.titles['Class Detail'] },
              detail.tables['Notes'] && { value: 'notes', label: detail.titles['Notes'] },
            ])}
            colorIndex={0}
            width="180px"
            maxLabelLength={21}
          >
            <TabPane tabId="summary" role="tabpanel">
              <HeaderComponent content={detail.descriptions['Summary']} />
              <DetailSummary
                data={detail.data['Summary']}
                tables={detail.tables['Summary']}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={detail.notes['Summary']} />
            </TabPane>
            <TabPane tabId="aum" role="tabpanel">
              <HeaderComponent content={detail.descriptions['AUM']} />
              <DetailAUM data={detail.data['AUM']} params={params} notify={notify} session={session} />
              <FooterComponent content={detail.notes['AUM']} />
            </TabPane>
            <TabPane tabId="leverage" role="tabpanel">
              <PageTabs
                options={_.compact([
                  _.get(detail.data, 'Leverage.Leverage.Net Assets') && { value: 'net-assets', label: 'Net Assets' },
                  _.get(detail.data, 'Leverage.Leverage.Leverage & Illiquid Assets') && {
                    value: 'leverage-illiquidity',
                    label: 'Leverage & Illiquid Assets',
                  },
                ])}
                colorIndex={0}
                width="180px"
                maxLabelLength={21}
              >
                <TabPane tabId="net-assets" role="tabpanel">
                  <HeaderComponent content={detail.descriptions['Leverage']} />

                  <DetailLeverageNetAssets
                    data={_.get(detail.data, 'Leverage.Leverage.Net Assets')}
                    currency={_.get(detail.data, 'Leverage.Leverage.Currency')}
                    params={params}
                    notify={notify}
                    session={session}
                  />
                  <FooterComponent content={detail.notes['Leverage']} />
                </TabPane>
                <TabPane tabId="leverage-illiquidity" role="tabpanel">
                  <HeaderComponent content={detail.descriptions['Leverage']} />
                  <DetailLeverageLeverageIlliquidity
                    data={_.get(detail.data, 'Leverage.Leverage.Leverage & Illiquid Assets')}
                    currency={_.get(detail.data, 'Leverage.Leverage.Currency')}
                    params={params}
                    notify={notify}
                    session={session}
                  />
                  <FooterComponent content={detail.notes['Leverage']} />
                </TabPane>
              </PageTabs>
            </TabPane>
            <TabPane tabId="fund-detail" role="tabpanel">
              <HeaderComponent content={detail.descriptions['Fund Detail']} />
              <DetailFundDetail
                tables={detail.tables['Fund Detail']}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={detail.notes['Fund Detail']} />
            </TabPane>
            <TabPane tabId="firm-detail" role="tabpanel">
              <HeaderComponent content={detail.descriptions['Firm Detail']} />
              <DetailFirmDetail
                tables={detail.tables['Firm Detail']}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={detail.notes['Firm Detail']} />
            </TabPane>
            <TabPane tabId="class-detail" role="tabpanel">
              <HeaderComponent content={detail.descriptions['Class Detail']} />
              <DetailClassDetail
                tables={detail.tables['Class Detail']}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={detail.notes['Class Detail']} />
            </TabPane>
            <TabPane tabId="notes" role="tabpanel">
              <HeaderComponent content={detail.descriptions['Notes']} />
              <DetailNotes tables={detail.tables['Notes']} params={params} notify={notify} session={session} />
              <FooterComponent content={detail.notes['Notes']} />
            </TabPane>
          </PageTabs>
        </TabPane>

        <TabPane tabId="riskperf" role="tabpanel">
          <PageTabs
            options={_.compact([
              riskPerf.data.calendar_return_output && {
                value: 'returns',
                label: riskPerf.titles.calendar_return_output,
              },
              riskPerf.data.risk_metrics_output && {
                value: 'risk-metrics',
                label: riskPerf.titles.risk_metrics_output,
              },
              riskPerf.data.var_output && { value: 'value-at-risk', label: riskPerf.titles.var_output },
              riskPerf.data.drawdown_output && { value: 'drawdown', label: riskPerf.titles.drawdown_output },
              riskPerf.data.market_risk_output && { value: 'market-risk', label: riskPerf.titles.market_risk_output },
              riskPerf.data.ir_output && { value: 'interest-rate-risk', label: riskPerf.titles.ir_output },
              riskPerf.data.cr_output && { value: 'credit-risk', label: riskPerf.titles.cr_output },
              riskPerf.data.exposure_output && { value: 'risk-exposure', label: riskPerf.titles.exposure_output },
              riskPerf.data.risk_contribution_output && {
                value: 'risk-contribution',
                label: riskPerf.titles.risk_contribution_output,
              },
              riskPerf.data.ffm_output && { value: 'fama-french', label: riskPerf.titles.ffm_output },
              riskPerf.data.corr_output && { value: 'correlation', label: riskPerf.titles.corr_output },
              riskPerf.data.impact_output && { value: 'portfolio-impact', label: riskPerf.titles.impact_output },
            ])}
            colorIndex={0}
            round={false}
            width="180px"
            maxLabelLength={21}
          >
            <TabPane tabId="returns" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.calendar_return_output} />
              <RiskPerformanceReturns
                data={riskPerf.data.calendar_return_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.calendar_return_output} />
            </TabPane>
            <TabPane tabId="risk-metrics" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.risk_metrics_output} />
              <RiskPerformanceRiskMetrics
                data={riskPerf.data.risk_metrics_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.risk_metrics_output} />
            </TabPane>
            <TabPane tabId="value-at-risk" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.var_output} />
              <RiskPerformanceValueAtRisk
                data={riskPerf.data.var_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.var_output} />
            </TabPane>
            <TabPane tabId="drawdown" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.drawdown_output} />
              <RiskPerformanceDrawdown
                data={riskPerf.data.drawdown_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.drawdown_output} />
            </TabPane>
            <TabPane tabId="market-risk" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.market_risk_output} />
              <RiskPerformanceMarketRisk
                data={riskPerf.data.market_risk_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.market_risk_output} />
            </TabPane>
            <TabPane tabId="risk-exposure" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.exposure_output} />
              <RiskPerformanceRiskExposure
                data={riskPerf.data.exposure_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.exposure_output} />
            </TabPane>
            <TabPane tabId="risk-contribution" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.risk_contribution_output} />
              <RiskPerformanceRiskContribution
                data={riskPerf.data.risk_contribution_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.risk_contribution_output} />
            </TabPane>
            <TabPane tabId="fama-french" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.ffm_output} />
              <RiskPerformanceFamaFrench
                data={riskPerf.data.ffm_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.ffm_output} />
            </TabPane>
            <TabPane tabId="correlation" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.corr_output} />
              <RiskPerformanceCorrelation
                data={riskPerf.data.corr_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.corr_output} />
            </TabPane>
            <TabPane tabId="interest-rate-risk" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.ir_output} />
              <RiskPerformanceInterestRateRisk
                data={riskPerf.data.ir_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.ir_output} />
            </TabPane>
            <TabPane tabId="credit-risk" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.cr_output} />
              <RiskPerformanceCreditRisk
                data={riskPerf.data.cr_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.cr_output} />
            </TabPane>
            <TabPane tabId="portfolio-impact" role="tabpanel">
              <HeaderComponent content={riskPerf.descriptions.impact_output} />
              <RiskPerformancePortfolioImpact
                options={riskPerf.data.impact_output}
                params={params}
                notify={notify}
                session={session}
              />
              <FooterComponent content={riskPerf.notes.impact_output} />
            </TabPane>
          </PageTabs>
        </TabPane>
      </PageTabs>
    </>
  );
}

export default InvestmentAnalysis;
