import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody, TabPane, Row, Col, Table } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function FamaFrench({ data, params, options = {}, notify, session }) {
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedModeloption, setSelectedModeloption] = useState(null);
  const [factorOptions, setFactorOptions] = useState([]);
  const [selectedFactorOption, setSelectedFactorOption] = useState(null);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSubjectOption, setSelectedSubjectOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _modelOptions = optionizeAll(_.keys(data));
    setModelOptions(_modelOptions);

    if (_modelOptions.length > 0) {
      setSelectedModeloption(_modelOptions[0]);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedModeloption) return;

    const modelData = data[selectedModeloption.value];

    const significant = modelData['significance results'];

    const _factorOptions = optionizeAll(significant.index);
    setFactorOptions(_factorOptions);

    const _subjectOptions = optionizeAll(significant.columns);
    setSubjectOptions(_subjectOptions);

    if (_factorOptions.length > 0) {
      setSelectedFactorOption(_factorOptions[0]);
    }

    if (_subjectOptions.length > 0) {
      setSelectedSubjectOption(_subjectOptions[0]);
    }
  }, [selectedModeloption]);

  if (!selectedModeloption) return;

  const modelData = data[selectedModeloption.value];

  return (
    <>
      <div className="tw-grid tw-grid-cols-64">
        <div className="tw-col-span-6">
          <div className="tw-text-left tw-font-bold">SELECT FAMA FRENCH GEOGRAPHY</div>
          <Select
            className="react-select mw-400px"
            value={selectedModeloption}
            onChange={setSelectedModeloption}
            options={modelOptions}
          />
        </div>
      </div>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Fama French Analysis
          <div className="tw-text-lg">{selectedModeloption.label}</div>
          <TimePeriod dates={modelData['table dates']} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-fama-french"
            data={modelData['regression results']}
            indexName=""
            indexWidth="250px"
            flip={false}
            cellFormatter={(value, row, col) =>
              ['Alpha'].includes(row)
                ? toPercTable(value)
                : ['R2'].includes(row)
                ? toPercTable(value, { precision: 0 })
                : toDeciTable(value)
            }
            title={['Fama French Analysis', selectedModeloption.label]}
            {...options.analysisTable}
          />
        </CardBody>
      </Card>
      <div className="tw-grid tw-grid-cols-64">
        <div className="tw-col-span-6">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-400px"
            value={selectedFactorOption}
            onChange={setSelectedFactorOption}
            options={factorOptions}
          />
        </div>
      </div>

      {selectedFactorOption && (
        <>
          <div className="tw-grid tw-grid-cols-12 tw-gap-4">
            <div className="tw-col-span-5">
              <Card>
                <CardBody>
                  <Table bordered striped>
                    <tbody>
                      <tr>
                        <td></td>
                        <td className="tw-font-semibold">Significance</td>
                      </tr>
                      {modelData['significance results'].columns.map((item, i) => {
                        const ind = modelData['significance results'].index.findIndex(
                          (v) => v === selectedFactorOption.value,
                        );
                        const value = modelData['significance results'].data[ind][i];
                        return (
                          <tr key={i}>
                            <td>{item}</td>
                            <td>{value ? 'Yes' : 'No'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
            <div className="tw-col-span-7">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedFactorOption.label}
                </CardHeader>
                <CardBody>
                  <TightChart
                    title={selectedFactorOption.label}
                    data={modelData['regression results']}
                    cols={[selectedFactorOption.value]}
                    flip={true}
                    dataType={['Alpha', 'R2'].includes(selectedFactorOption.value) ? 'percentage' : 'decimal'}
                    options={{
                      indexAxis: 'y',
                    }}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </>
      )}

      <div className="tw-grid tw-grid-cols-6">
        <div className="tw-col-span-6">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-400px"
            value={selectedSubjectOption}
            onChange={setSelectedSubjectOption}
            options={subjectOptions}
          />
        </div>
      </div>

      {selectedSubjectOption && (
        <>
          <div className="tw-grid tw-grid-cols-12 tw-gap-4">
            <div className="tw-col-span-5">
              <Card>
                <CardBody>
                  <Table bordered striped>
                    <tbody>
                      <tr>
                        <td></td>
                        <td className="tw-font-semibold">Significance</td>
                      </tr>
                      {modelData['significance results'].index.map((item, i) => {
                        const ind = modelData['significance results'].columns.findIndex(
                          (v) => v === selectedSubjectOption.value,
                        );
                        const value = modelData['significance results'].data[i][ind];
                        return (
                          <tr key={i}>
                            <td>{item}</td>
                            <td>{value ? 'Yes' : 'No'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
            <div className="tw-col-span-7">
              <Card>
                <CardHeader tag="h4" className="mt-0 text-center">
                  {selectedSubjectOption.label}
                </CardHeader>
                <CardBody>
                  <TightChart
                    title={selectedSubjectOption.label}
                    data={modelData['regression results']}
                    cols={[selectedSubjectOption.value]}
                    rows={['MKT', 'SMB', 'HML', 'MOM']}
                    options={{
                      indexAxis: 'y',
                    }}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FamaFrench;
