/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Collapse, NavbarBrand, Navbar, NavItem, Nav, Container } from 'reactstrap';
import Select from 'react-select';
import AnalyzerMenus from './AnalyzerMenus';

const apiUrl = process.env.REACT_APP_API_URL || '';

function AdminNavbar(props) {
  const { session = {} } = props || {};
  const { permissions = {} } = session || {};

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [color, setColor] = React.useState('navbar-transparent');
  const location = useLocation();
  const navigate = useNavigate();
  const current = (props.currentRoute && props.currentRoute) || {};
  const title = current.name || '';
  let submenuOpts = current.subs || [];
  submenuOpts = submenuOpts.filter((menu) => {
    if (menu.permission) return permissions[menu.permission];
    return true;
  });

  const submenuOpt = submenuOpts.find((v) => v.value === current.sub);

  React.useEffect(() => {
    window.addEventListener('resize', updateColor);
  });
  React.useEffect(() => {
    if (window.outerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }, [location]);

  const handleSubMenuChange = (v) => {
    navigate({ pathname: `${current.parent}/${v.value}`, search: location.search }, { replace: false });
  };

  const topTitle =
    submenuOpts.length > 0 ? (
      <Select
        className="react-select"
        name="submenus"
        value={submenuOpt}
        onChange={handleSubMenuChange}
        options={submenuOpts}
      />
    ) : (
      title
    );

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white');
    } else {
      setColor('navbar-transparent');
    }
  };
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    setSidebarOpen(!sidebarOpen);
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor('bg-white');
    } else {
      setColor('navbar-transparent');
    }
    setCollapseOpen(!collapseOpen);
  };
  return (
    <>
      <Navbar className={classnames('navbar-absolute fixed-top', color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={props.handleMiniClick}
              >
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames('navbar-toggle', {
                toggled: sidebarOpen,
              })}
            >
              <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              <div
                className={classnames('d-none d-md-block', {
                  'font-weight-bold': current.titleBold,
                })}
                style={{ minWidth: '500px' }}
              >
                {topTitle}
                <span className="ml-5">
                  <AnalyzerMenus session={session} />
                </span>
              </div>
              <div className="d-block d-md-none" style={{ minWidth: '500px' }}>
                {topTitle}
              </div>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse className="justify-content-end" navbar isOpen={collapseOpen}>
            <Nav navbar>
              {props.children && props.children}
              <NavItem>
                {props.session ? (
                  <a className="btn btn-red" href={`${apiUrl}/api/cognito/logout`}>
                    <i className="fa fa-user-circle" aria-hidden="true"></i>
                    Logout
                  </a>
                ) : (
                  <a className="btn btn-red" href={`${apiUrl}/api/cognito/login`}>
                    <i className="fa fa-user-circle" aria-hidden="true"></i>
                    Login
                  </a>
                )}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
