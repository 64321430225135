import Landing from 'views/Landing';
import Users from 'views/Users';
import Analyzers from 'views/Analyzers';
import AnalyzersPA from 'views/AnalyzersPA';
import AssetDiscovery from 'views/AssetDiscovery';
import RiskGuide from 'views/RiskGuide';
import AdvancedAnalytics from 'views/advanced-analytics/Base';
import InvestmentAnalysis from 'views/investment-analysis/Base';
import InvestmentComparison from 'views/investment-comparison/Base';
import ClientPortfolio from 'views/my-flare/ClientPortfolio';
import ModelPortfolios from 'views/my-flare/ModelPortfolios';
import AlternativeDashboard from 'views/my-flare/AlternativeDashboard';
import AssetClasses from 'views/my-flare/AssetClasses';
import BuildPortfolio from 'views/portfolio-analysis/BuildPortfolio';
import PortfolioAnalysisSingleAdvanced from 'views/portfolio-analysis/single/advanced/Base';
import PortfolioAnalysisSingleAnalyze from 'views/portfolio-analysis/single/analyze/Base';
import PortfolioAnalysisMultipleAdvanced from 'views/portfolio-analysis/multiple/advanced/Base';
import PortfolioAnalysisMultipleAnalyze from 'views/portfolio-analysis/multiple/analyze/Base';

const routes = [
  {
    path: '/landing',
    name: 'Introducing ALTS',
    icon: 'nc-icon nc-bank',
    component: Landing,
    layout: '',
    redirect: true,
    titleBold: true,
  },
  {
    path: '/analyzers',
    name: 'Analyzers',
    icon: 'nc-icon nc-bank',
    component: Analyzers,
    layout: '',
    permission: 'analyzers',
  },
  {
    path: '/analyzers-pa',
    name: 'Portfolio Analysis',
    component: AnalyzersPA,
    layout: '',
    permission: 'analyzers',
    redirect: true,
  },
  {
    path: '/analyzers/asset-discovery-aa',
    name: 'Advanced Analytics',
    icon: 'nc-icon nc-bank',
    component: AssetDiscovery,
    layout: '',
    permission: 'aa',
  },
  {
    path: '/analyzers/asset-discovery-ia',
    name: 'Fund Analysis',
    icon: 'nc-icon nc-bank',
    component: AssetDiscovery,
    layout: '',
    redirect: true,
  },
  {
    path: '/analyzers/asset-discovery-ic',
    name: 'Fund Comparison',
    icon: 'nc-icon nc-bank',
    component: AssetDiscovery,
    layout: '',
    redirect: true,
  },
  {
    path: '/advanced-analytics',
    name: 'Advanced Analytics',
    icon: 'nc-icon nc-bank',
    component: AdvancedAnalytics,
    layout: '',
    redirect: true,
  },
  {
    path: '/investment-analysis',
    name: 'Fund Analysis',
    icon: 'nc-icon nc-bank',
    component: InvestmentAnalysis,
    layout: '',
    redirect: true,
  },
  {
    path: '/investment-comparison',
    name: 'Fund Comparison',
    icon: 'nc-icon nc-bank',
    component: InvestmentComparison,
    layout: '',
    redirect: true,
    permission: 'ic',
  },
  {
    collapse: true,
    name: 'My Alts',
    icon: 'nc-icon nc-book-bookmark',
    state: 'pagesCollapse',
    permission: 'myflare',
    views: [
      // {
      //   path: '/portfolio',
      //   name: 'Client Portfolios',
      //   mini: 'CP',
      //   component: ClientPortfolio,
      //   layout: '',
      //   permission: 'my-flare-client-portfolios',
      // },
      // {
      //   path: '/advisor-holdings',
      //   name: 'Advisor Holdings',
      //   mini: 'AH',
      //   component: ClientPortfolio,
      //   layout: '',
      // },
      // {
      //   path: '/advisor-watchlist',
      //   name: 'Advisor Watchlist',
      //   mini: 'AW',
      //   component: ClientPortfolio,
      //   layout: '',
      // },
      {
        path: '/model-portfolio',
        name: 'Model Portfolios',
        mini: 'MP',
        component: ModelPortfolios,
        layout: '',
        permission: 'myflare-model-portfolios',
      },
      {
        path: '/asset-classes',
        name: 'Asset Classes',
        mini: 'AC',
        component: AssetClasses,
        layout: '',
        permission: 'myflare-asset-classes',
      },
      {
        path: '/alternatives',
        name: 'Funds Dash',
        mini: 'FD',
        component: AlternativeDashboard,
        layout: '',
        permission: 'myflare-alternative-funds',
      },
    ],
  },
  {
    path: '/build-portfolio',
    name: 'Build Portfolio',
    icon: 'nc-icon nc-bank',
    component: BuildPortfolio,
    redirect: true,
    layout: '',
    permission: 'pa-build',
  },
  {
    path: '/compare-portfolios',
    name: 'Compare Portfolios',
    icon: 'nc-icon nc-bank',
    component: BuildPortfolio,
    redirect: true,
    layout: '',
    permission: 'pa-build',
  },
  {
    path: '/portfolio-analysis-multiple-advanced',
    name: 'Portfolio Analysis',
    icon: 'nc-icon nc-bank',
    component: PortfolioAnalysisMultipleAdvanced,
    redirect: true,
    layout: '',
  },
  {
    path: '/portfolio-analysis-multiple-analyze',
    name: 'Portfolio Analysis',
    icon: 'nc-icon nc-bank',
    component: PortfolioAnalysisMultipleAnalyze,
    redirect: true,
    layout: '',
  },
  {
    path: '/portfolio-analysis-single-advanced',
    name: 'Portfolio Analysis',
    icon: 'nc-icon nc-bank',
    component: PortfolioAnalysisSingleAdvanced,
    redirect: true,
    layout: '',
  },
  {
    path: '/portfolio-analysis-single-analyze',
    name: 'Portfolio Analysis',
    icon: 'nc-icon nc-bank',
    component: PortfolioAnalysisSingleAnalyze,
    redirect: true,
    layout: '',
  },
  {
    path: '/risk-guide',
    name: 'Risk Guide',
    icon: 'nc-icon nc-bank',
    component: RiskGuide,
    layout: '',
    permission: 'risk-guide',
  },
  {
    collapse: true,
    path: '/users',
    name: 'User Management',
    icon: 'nc-icon nc-user-run',
    state: 'userMenuCollapse',
    permission: 'user-management',
    views: [
      {
        path: '/assigned-users',
        name: 'Assigned Users',
        mini: 'CP',
        component: Users,
        layout: '',
        permission: 'user-management',
        state: 'assigned',
      },
      {
        path: '/pending-users',
        name: 'Pending Users',
        mini: 'CP',
        component: Users,
        layout: '',
        permission: 'user-management',
        state: 'pending',
      },
    ],
  },
];

export default routes;
