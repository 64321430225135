import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { removeEmptyCols, flipDF } from 'helpers/data-frame';
import { toDeci, toPerc, formatDateShort, formatCurrency } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function AUM({ data: initData, params, notify, session }) {
  const [data, setData] = useState(null);
  const [subjectOptions, setSubjectOptions, selectedSubjectOption, setSelectedSubjectOption] = useArrayState();
  const [periodOptions, setPeriodOptions, selectedPeriodOption, setSelectedPeriodOption] = useArrayState();

  useEffect(() => {
    if (!initData) return;

    const columns = initData.columns.map((col, ind) => {
      return `${col} (${initData.data[0][ind]})`;
    });

    const index = initData.index.filter((v, ind) => ind !== 0);
    const data = initData.data.filter((v, ind) => ind !== 0);

    setData({ index, columns, data });
  }, [initData]);

  useEffect(() => {
    if (!data) return;

    const _subjects = data.columns.filter((col, ind) => {
      return data.data.some((item) => item[ind]);
    });

    const _subjectsOptions = optionizeAll(_subjects);
    setSubjectOptions(_subjectsOptions);

    const _period = _.orderBy(
      data.index.filter((v) => v !== 'Currency'),
      [],
      ['desc'],
    );
    const _periodOptions = optionizeAll(_period, { labelFormatter: formatDateShort });
    setPeriodOptions(_periodOptions);
  }, [data]);

  if (!data || !selectedSubjectOption || !selectedPeriodOption) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          AUM (000,000)
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="aum"
            data={data}
            indexFormatter={formatDateShort}
            cellFormatter={(value, row, col) => {
              if (row === 'Currency') return value;

              return formatCurrency(value);
            }}
            indexName="as of date"
            sortIndexes={[0]}
            useSort
            title="AUM (000,000)"
          />
        </CardBody>
      </Card>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select tw-w-[400px]"
            value={selectedSubjectOption}
            onChange={setSelectedSubjectOption}
            options={subjectOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              AUM (000,000)
            </CardHeader>
            <CardBody>
              <TightChart
                title="AUM (000,000)"
                data={data}
                cols={[selectedSubjectOption.value]}
                rows={(rows) => rows.filter((v) => v !== 'Currency')}
                dataType="currency"
                options={{
                  scales: {
                    x: {
                      beginAtZero: false,
                      ticks: {
                        callback: (value, index, ticks) => {
                          return formatDateShort(data.index[index]);
                        },
                      },
                    },
                  },
                }}
              />
            </CardBody>
          </Card>
        </>
      </AutoColumns>

      <AutoColumns>
        <>
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select tw-w-[300px]"
            value={selectedPeriodOption}
            onChange={setSelectedPeriodOption}
            options={periodOptions}
          />
          <Card>
            <CardHeader tag="h4" className="mt-0 text-center">
              AUM (000,000)
            </CardHeader>
            <CardBody>
              <TightChart
                title="AUM (000,000)"
                data={data}
                cols={[selectedPeriodOption.value]}
                dataType="currency"
                flip
                wraplabelX
              />
            </CardBody>
          </Card>
        </>
      </AutoColumns>
    </>
  );
}

export default AUM;
