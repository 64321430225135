import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';

function ValueAtRisk({ data: { data, table_dates }, options = {}, notify, session }) {
  const [varOptions, setVarOptions] = useState([]);
  const [selectedVarOption, setSelectedVarOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _varOptions = optionizeAll(data.index);
    setVarOptions(_varOptions);

    if (_varOptions.length > 0) {
      setSelectedVarOption(_varOptions[0]);
    }
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Value at Risk
          <div className="tw-leading-none">
            <span className="tw-text-sm">(95% Confidence)</span>
          </div>
          <TimePeriod dates={table_dates} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-var"
            data={data}
            indexName=""
            indexWidth="250px"
            dataType="percentage"
            title={['Value at Risk', '(95% Confidence)']}
            {...options.valueAtRiskTable}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          <div className="tw-text-left tw-font-bold">SELECT VAR METRIC</div>
          <Select
            className="react-select mw-400px"
            value={selectedVarOption}
            onChange={setSelectedVarOption}
            options={varOptions}
          />
          {selectedVarOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                {selectedVarOption.label}
              </CardHeader>
              <CardBody>
                <TightChart
                  title={selectedVarOption.label}
                  data={data}
                  cols={[selectedVarOption.value]}
                  flip={true}
                  wraplabelX={true}
                  dataType="percentage"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default ValueAtRisk;
