import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Chart } from 'react-chartjs-2';
import _ from 'lodash';
import { Card, CardHeader, CardBody } from 'reactstrap';
import TightTable from 'components/TightTable';
import TightChart from 'components/TightChart';
import TimePeriod, { formatDates } from 'components/TimePeriod';
import LineChart from 'components/LineChart';
import { AutoColumns } from 'components/Columns';
import { useArrayState } from 'components/CustomHooks';
import { toDeci, toDeciTable, toPercTable, formatDate } from 'helpers/formatter';
import { optionizeValue, optionizeAll, getValue, getDate } from 'helpers/select';
import { filterDF } from 'helpers/data-frame';
import { colors } from 'helpers/chart';

function Drawdown({ data, params, options = {}, notify, session }) {
  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAssetOption, setSelectedAssetOption] = useState(null);
  const [holdingOptions, setHoldingOptions] = useState([]);
  const [selectedHoldingOption, setSelectedHoldingOption] = useState(null);

  useEffect(() => {
    if (!data) return;

    const _asset = data['summary table'].columns;
    const _assetOptions = optionizeAll(_asset);
    setAssetOptions(_assetOptions);

    if (_assetOptions.length > 0) {
      setSelectedAssetOption(_assetOptions[0]);
    }

    const _holdings = _asset.filter((val) => ![params.port1Name, params.benchmark].includes(val));
    const _holdingOptions = optionizeAll(_holdings);
    setHoldingOptions(_holdingOptions);

    if (_holdingOptions.length > 0) {
      setSelectedHoldingOption(_holdingOptions[0]);
    }
  }, [data]);

  const drawdownChart = useMemo(() => {
    if (!data) return null;
    const gdata = data['graph data'];

    const targets = [params.port1Name, params.benchmark];
    if (selectedHoldingOption) targets.push(selectedHoldingOption.value);

    const dframe = filterDF(gdata, null, targets);
    const datas = [];
    _.each(targets, () => {
      datas.push([]);
    });

    _.each(dframe.data, (row) => {
      _.each(targets, (target, i) => {
        datas[i].push(row[i] * 100);
      });
    });

    const tdata = {
      labels: gdata.index.map((v) => v.substring(0, 7)),
      datasets: datas.map((data, i) => {
        return {
          label: targets[i],
          data: datas[i],
          fill: false,
          backgroundColor: colors[i],
          borderColor: colors[i],
          borderWidth: 2,
          tension: 0,
          pointRadius: 3,
          pointHoverRadius: 4,
        };
      }),
    };

    const options = {
      plugins: {
        legend: { display: true },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item) => {
              return `${item.label}: ${toDeci(item.raw)}%`;
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 30,
            maxRotation: 0,
            minRotation: 0,
            color: 'black',
          },
          beginAtZero: false,
        },
        y: {
          title: {
            display: true,
          },
          ticks: {
            callback: (val) => toDeci(val, { precision: 1, absolute: true, suffix: '%' }),
            color: ({ tick }) => (tick.value < 0 ? 'red' : 'black'),
          },
          beginAtZero: false,
        },
      },
    };

    return { data: tdata, options };
  }, [data, selectedHoldingOption]);

  return (
    <>
      <Card>
        <CardHeader tag="h4" className="mt-0 text-center">
          Drawdown Analysis
          <TimePeriod dates={data['summary table dates']} />
        </CardHeader>
        <CardBody>
          <TightTable
            suffix="risk-performance-drawdown"
            data={data['summary table']}
            indexName=""
            indexWidth="250px"
            useSort={true}
            cellFormatter={(value, row, col, rowInd, colInd, flip) => {
              const label = flip ? col : row;
              return ['CAGR', 'Standard Deviation', 'Maximum Drawdown'].includes(label)
                ? toPercTable(value)
                : ['Calmar Ratio', 'MAR Ratio (RoMaD)', 'Sterling Ratio'].includes(label)
                ? toDeciTable(value)
                : value;
            }}
            title={['Drawdown Analysis', formatDates(data['summary table dates'])]}
            {...options.drawdownHistoryTable}
          />
        </CardBody>
      </Card>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-start-2 tw-col-span-4">
          {holdingOptions.length > 1 && (
            <>
              <div className="tw-text-left tw-font-bold">SELECT HOLDING</div>
              <Select
                className="react-select mw-400px"
                value={selectedHoldingOption}
                onChange={setSelectedHoldingOption}
                options={holdingOptions}
              />
            </>
          )}
          {drawdownChart && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Drawdown History
              </CardHeader>
              <CardBody>
                <LineChart title="Drawdown History" data={drawdownChart.data} options={drawdownChart.options} />
              </CardBody>
            </Card>
          )}
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-6 tw-gap-4">
        <div className="tw-col-span-6">
          <div className="tw-text-left tw-font-bold">SELECT</div>
          <Select
            className="react-select mw-400px"
            value={selectedAssetOption}
            onChange={setSelectedAssetOption}
            options={assetOptions}
          />
          {selectedAssetOption && (
            <Card>
              <CardHeader tag="h4" className="mt-0 text-center">
                Drawdown Detail
                <div className="tw-text-lg">{selectedAssetOption.label}</div>
              </CardHeader>
              <CardBody>
                <TightTable
                  suffix="risk-performance-drawdown-detail"
                  data={data['details table'][selectedAssetOption.value]}
                  indexName=""
                  indexWidth="250px"
                  cellFormatter={(value, row, col) => (['Drawdown'].includes(col) ? toPercTable(value) : value)}
                  title={['Drawdown Detail', selectedAssetOption.label]}
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default Drawdown;
